<section class="page-tree">
  <div class="container p-5">
    <div class="row text-center">
      <div class="col-12">
        <h1 class="font-primary-color title-modal title-section-3">How PoolChief Works</h1>
      </div>
    </div>
    <br />
    <span class="d-none d-md-block">
      <br /> <br>
    </span>
    <div class="row">
      <div class="col-12 col-md-3">
        <div class="row text-center">
          <div class="col">
            <img
              src="../../../assets/aboutUs/one.png"
              alt=""
              class="image-number"
            />
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-4 mb-4">
            <h6 class="font-primary-color title-modal">Create an Account</h6>
            <span class="d-none d-md-block"><br></span>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <span class="paragraph font-primary-color">
              It only takes a few <br> minutes to get set up.
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="row text-center">
          <div class="col">
            <img
            src="../../../assets/aboutUs/two.png"
              alt=""
              class="image-number"
            />
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-4 mb-4">
            <h6 class="font-primary-color title-modal">
              View and accept <br> job offers
            </h6>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <span class="paragraph font-primary-color">
              Pick the recurring pool <br> cleaning accounts that <br> work for you

            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="row text-center">
          <div class="col">
            <img
            src="../../../assets/aboutUs/three.png"
              alt=""
              class="image-number"
            />
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-4 mb-4">
            <h6 class="font-primary-color title-modal">Complete the <br> work</h6>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <span class="paragraph font-primary-color">
              Check-in and out of <br>  your pools when done.
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="row text-center">
          <div class="col">
            <img
            src="../../../assets/aboutUs/four.png"
              alt=""
              class="image-number"
            />
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-4 mb-4">
            <h6 class="font-primary-color title-modal">Get Paid</h6>
            <span class="d-none d-md-block"><br></span>
          </div>
        </div>
        <div class="row text-center">
          <div class="col">
            <span class="paragraph font-primary-color">
              Funds are deposited <br> every week.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

