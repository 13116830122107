import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-optional-info',
  templateUrl: './optional-info.component.html',
  styleUrls: ['./optional-info.component.css']
})
export class OptionalInfoComponent implements OnInit {
  aditionalInfoForm: FormGroup;
  constructor(private fb: FormBuilder) { }

  ngOnInit(){
    this.aditionalInfoForm = this.fb.group({
      instructions: [''],
      anythingElse: ['']
    });
  }

}
