import { AboutUsComponent } from './about-us.component';
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

const routes = [
{
  path: '',
  component: AboutUsComponent

}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class AboutUsRoutingModule { }
