<div class="scrollable-content">
<h5 class="font-primary-color title-modal">Your Quote is ready!</h5>
<h6 class="font-primary-color subtitle-modal">Where shoud we send it? Please fill out the form.</h6>
<br>
<form [formGroup]="personalForm">
  <div class="row">
    <div class="form-group col-6">
      <input type="text" placeholder="First Name" class="form-control" formControlName="firstName" id="firstName" autocomplete="off">
      <span *ngIf="personalForm.get('firstName').invalid && (personalForm.get('firstName').dirty)">
        <span *ngIf="personalForm.get('firstName').errors.required" style="color: brown;">
          First Name is required.
        </span>
      </span>
    </div>

    <div class="form-group col-6">
      <input type="text" placeholder="Last Name" class="form-control" formControlName="lastName"  autocomplete="off">
      <span *ngIf="personalForm.get('lastName').invalid && (personalForm.get('lastName').dirty)">
        <span *ngIf="personalForm.get('lastName').errors.required" style="color: brown;">
          Last Name is required.
        </span>
      </span>
    </div>

    <div class="form-group col-12 mt-3">
      <input type="email" placeholder="Email" class="form-control" formControlName="email" autocomplete="off">
      <span *ngIf="personalForm.get('email').invalid && (personalForm.get('email').dirty)">
        <span *ngIf="personalForm.get('email').errors.required" style="color: brown;">
          Email is required.
        </span>
      </span>
    </div>

    <div class="form-group col-12 mt-3">
      <input type="tel" (focus)="isPhoneNumberFocused = true" (blur)="isPhoneNumberFocused = false" placeholder="Phone number" class="form-control" formControlName="phoneNumber"  mask="(000)-000-0000" autocomplete="off">
      <span *ngIf="personalForm.get('phoneNumber').invalid && (personalForm.get('phoneNumber').dirty) && personalForm.get('phoneNumber').touched"  style="color: brown;">
        <span [hidden]="isPhoneNumberFocused" *ngIf="personalForm.get('phoneNumber').errors.pattern">Invalid Phone Number.</span>
        <span *ngIf="personalForm.get('phoneNumber').errors.required">Phone Number is required.</span>
    </span>
    </div>
  </div>
</form>
</div>