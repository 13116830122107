<mat-drawer-container hasBackdrop="true">
  <mat-drawer #drawer mode="over">
    <div class="row text-center">
      <div class="col text-center">
        <a class="navbar-brand" href="#" routerLink="/">
          <img src="./../../assets/aboutUs/logo.png" alt="" width="175px" />
        </a>
        <div class="row text-center">
          <div class="col mt-5">
            <ul class="navbar-nav text-center">
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" (click)="drawer.toggle(); scrollToElement(one)">
                  The service
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" (click)="drawer.toggle(); scrollToElement(two)">
                  How it works
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" (click)="drawer.toggle(); scrollToElement(three)">
                  Our Pool Pros
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" (click)="drawer.toggle(); scrollToElement(four)">
                  Why PoolChief
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" (click)="drawer.toggle(); scrollToElement(five)">
                  FAQs
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" (click)="contactUs()">
                  Contact us
                </button>
              </li>
              <div class="row text-center">
                <div class="col mt-5">
                  <li class="nav-item">
                    <a class="nav-link secondary-color btn-get-quote btn" (click)="drawer.toggle(); getLocation()">Get a
                      Quote</a>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <nav class="navbar navbar-expand-lg navbar-light" [ngClass]="isMobile ? 'mobile-navbar' : 'container'" #up>
      <div class="navbar-brand p-0 mt-2" routerLink="/">
        <img src="./../../assets/images/brand.png" alt="" width="175px" class="img-logo" />
      </div>
      <button class="navbar-toggler btn-link" type="button" (click)="drawer.toggle()">
        <mat-icon class="font-primary-color">menu</mat-icon>
      </button>

      <div class="collapse navbar-collapse navbar-align-g d-none d-xl-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <button class="btn btn-link nav-link" (click)="scrollToElement(one)">
              The service
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" (click)="scrollToElement(two)">
              How it works
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" (click)="scrollToElement(three)">
              Our Pool Pros
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" (click)="scrollToElement(four)">
              Why PoolChief
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" (click)="scrollToElement(five)">
              FAQs
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link btn-block nav-link" (click)="contactUs()">
              Contact us
            </button>
          </li>
          <li class="nav-item ml-4 d-none d-lg-block">
            <a class="nav-link primary-color btn-get-quote btn btn-get-quote-bar" (click)="getLocation()">Get a
              Quote</a>
          </li>
        </ul>
      </div>
    </nav>
    <!--[ngClass]="isMobile ? 'bg-mobile' : 'bg-image'"-->
    <section class="algo" [ngClass]="isMobile ? 'bg-mobile' : 'bg-image'">
      <div class="page-one container">
        <div class="content col-12 col-md-12 col-lg-7">
          <h1 class="text-white d-none d-md-block" style="line-height: normal">
            Hire the <span class="font-alterna-color">best pool cleaning</span>
            <br />service near you with the<br />click of a button
          </h1>
          <h1 class="text-white d-block d-md-none">
            Hire the <br />
            <span class="font-alterna-color">best pool cleaning</span>
            <br />service near you<br />with the click<br />of a button
          </h1>
          <h6 class="d-none d-sm-block" style="margin-top: 22px !important; margin-bottom: 22px !important">
            Fully vetted pros. Affordable, fast online ordering, <br />
            satisfaction guaranteed and fantastic customer service.
          </h6>
          <h6 class="d-block d-sm-none" style="margin-top: 21px !important; margin-bottom: 21px !important">
            Fully vetted pros.<br />
            Affordable, fast online <br />
            ordering, satisfaction <br />
            guaranteed and fantastic <br />
            customer service.
          </h6>

          <div class="zip-code-place mt-3" *ngIf="!isMobile">
            <div class="one">
              <form [formGroup]="zipCodeForm">
                <input type="text" class="zip-code-input" placeholder="Your zip code" formControlName="zipcode"
                  (keydown.enter)="getLocation()" (keypress)="numberOnly($event)" maxlength="5" cdkFocusInitial />
              </form>
            </div>
            <div class="two">
              <button [class.spinner]="loading" [disabled]="loading" class="btn get-quote" type="submit"
                (click)="getLocation()">
                <span> See my price</span> &nbsp;<mat-icon class="">trending_flat</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="row" style="margin: 0px !important" *ngIf="isMobile">
      <div class="col content-button-mobile">
        <div class="mobile-get-cuote">
          <span class="font-primary-color ml-1 mb-2" style="font-family: 'MuseoSansBold'">Get a fast, free quote</span>
          <div class="zip-code-place-mobile">
            <div class="one">
              <form [formGroup]="zipCodeForm">
                <input type="text" class="zip-code-input" placeholder="Your Zip Code" maxlength="5"
                  formControlName="zipcode" (keydown.enter)="getLocation()" (keypress)="numberOnly($event)"
                  cdkFocusInitial />
              </form>
            </div>
            <div class="two">
              <button class="btn get-quote" type="submit" (click)="getLocation()">
                <span> See my price</span> &nbsp;<mat-icon class="">trending_flat</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div #one id="service">
      <app-section-one></app-section-one>
    </div>

    <div #two id="how-it-works">
      <app-section-two></app-section-two>
    </div>

    <section class="banner-space">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-7">
            <h4 class="text-white banner-title" [ngClass]="isMobile ? 'text-center' : ''">
              Hire the best pool cleaning near you. Fully vetted pool pros.
              Affordable. Fast Online Ordering
            </h4>
          </div>
          <div class="col-12 col-md-5 btn-cuote-mobile-banner">
            <button class="btn btn-banner title-modal btn-banner-size" (click)="getLocation()">
              Get a Quote
            </button>
          </div>
        </div>
      </div>
    </section>

    <div #three id="our-pool-pros">
      <app-section-three></app-section-three>
    </div>

    <div #four id="why-poolchief">
      <app-section-four></app-section-four>
    </div>

    <div #five id="faqs">
      <app-section-five></app-section-five>
    </div>

    <div class="row p-0 m-0 d-xl-none">
      <div class="col p-0 m-0">
        <img src="../../assets/assets_WebP/images/img-banner-mobile.webp" loading="lazy" class="img-fluid w-100" />
      </div>
    </div>

    <section class="banner-second">
      <div class="other">
        <div class="container">
          <div class="row">
            <div class="col-12 col-xl-7" [ngClass]="isMobile ? 'text-center' : ''">
              <h1 class="text-white title-second-banner" [ngClass]="isMobile ? 'text-center' : ''">
                Hire the best pool cleaning near you. Fully vetted pool pros.
                Affordable. Fast Online Ordering
              </h1>
              <br /><br />
              <button class="btn btn-banner title-modal btn-banner-size" (click)="openRegisterModal()">
                Get a Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="footer">
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 col-sm-3">
            <div class="row" style="padding-bottom: 15px">
              <div class="col p-0">
                <img src="../../assets/aboutUs/logo.png" loading="lazy" alt="" class="logo-footer" routerLink="/"
                  style="cursor: pointer" />
              </div>
            </div>
            <div class="row">
              <div class="col mt-2 mb-2 pl-sm-0">
                <p class="text-white paragraph-footer">
                  PoolChief is an innovative <br />
                  marketplace that leverages <br />
                  technology to connect <br />
                  swimming pool owners with the <br />
                  best local pool cleaning pros. <br />
                </p>
              </div>
            </div>
            <div class="row d-none d-sm-block">
              <div class="col-12 p-0">
                <a href="https://www.facebook.com/PoolChief/" target="_blank"><img
                    src="../../../assets/images/facebook.png" loading="lazy" alt="" class="icon-social" /></a>
                <a href="https://www.instagram.com/poolchief" target="_blank"><img
                    src="../../../assets/images/intagram.png" loading="lazy" alt="" class="icon-social" /></a>
                <a href="https://www.youtube.com/channel/UCfO9nTHyj8PJvmjB3SrPNCg" target="_blank"><img
                    src="../../../assets/images/youtube.png" loading="lazy" alt="" class="icon-social" /></a>
                <a href="https://twitter.com/GetPoolChief" target="_blank"><img src="../../../assets/images/twitter.png"
                    loading="lazy" alt="" class="icon-social" /></a>
                <a href="https://www.linkedin.com/company/poolchief" target="_blank"><img
                    src="../../../assets/images/linkedin.png" loading="lazy" alt="" class="icon-social" /></a>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-5">
            <div class="row">
              <div class="col-6 padding-footer-experimental px-0">
                <div class="row">
                  <div class="col">
                    <button class="btn link-footer" routerLink="/about">
                      About us
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button class="btn link-footer" (click)="scrollToElement(one)">
                      The Service
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button class="btn link-footer" (click)="scrollToElement(two)">
                      How it works
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button class="btn link-footer" (click)="scrollToElement(three)">
                      Our Pool Pros
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-6 padding-footer-experimental px-0">
                <div class="row">
                  <div class="col">
                    <button class="btn link-footer" (click)="scrollToElement(four)">
                      Why PoolChief
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button class="btn link-footer" (click)="contactUs()">
                      Contact us
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button class="btn link-footer" (click)="scrollToElement(five)">
                      FAQs
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button class="btn link-footer" routerLink="/become-pool-pro">
                      Become a Pool Pro
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-getquote></app-getquote>
        </div>

        <div class="row d-block d-sm-none">
          <div class="col-12">
            <a href="https://www.facebook.com/PoolChief/" target="_blank"><img src="../../../assets/images/facebook.png"
                loading="lazy" alt="" class="icon-social" /></a>
            <a href="https://www.instagram.com/poolchief" target="_blank"><img src="../../../assets/images/intagram.png"
                loading="lazy" alt="" class="icon-social" /></a>
            <a href="https://www.youtube.com/channel/UCfO9nTHyj8PJvmjB3SrPNCg" target="_blank"><img
                src="../../../assets/images/youtube.png" loading="lazy" alt="" class="icon-social" /></a>
            <a href="https://twitter.com/GetPoolChief" target="_blank"><img src="../../../assets/images/twitter.png"
                loading="lazy" alt="" class="icon-social" /></a>
            <a href="https://www.linkedin.com/company/poolchief" target="_blank"><img
                src="../../../assets/images/linkedin.png" loading="lazy" alt="" class="icon-social" /></a>
          </div>
        </div>

        <div class="row">
          <div class="col mt-3">
            <span class="text-white">© 2021 PoolChief All rights reserved &nbsp;&nbsp; |
              <button class="btn link-footer-term" routerLink="/terms">
                Terms of Service
              </button>
              |
              <button class="btn link-footer-term" routerLink="/privacy">
                Privacy Policy
              </button>
            </span>
          </div>
        </div>
      </div>
    </section>

  </mat-drawer-content>
</mat-drawer-container>
