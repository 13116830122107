import { PersonalInfoComponent } from './../personal-info/personal-info.component';
import { UtilitiesService } from './../../../utils/utilities.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { objectExists } from 'src/app/utils/utilities.service';
import { AddressComponent } from '../address/address.component';
import { ServiceDetailComponent } from '../service-detail/service-detail.component';
import { MatStepper } from '@angular/material/stepper';
import Swal from 'sweetalert2';
import 'firebase/functions';
import firebase from 'firebase/app';
import { ServiceConfirmedComponent } from '../service-confirmed/service-confirmed.component';
import { CommonService } from 'src/app/utils/common.service';
import * as alertify from 'alertifyjs';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  @Input() zipcode: number;
  @Input() showButtonBack: boolean;
  @ViewChild(PersonalInfoComponent)
  personalInfoComponent: PersonalInfoComponent;
  @ViewChild(AddressComponent) poolAddressComponent: AddressComponent;
  @ViewChild(ServiceDetailComponent)
  poolDetailComponent: ServiceDetailComponent;
  @ViewChild(ServiceConfirmedComponent)
  serviceConfirmedComponent: ServiceConfirmedComponent;
  showButton = true;
  detector = navigator.userAgent;
  customerInfo: any;
  isEditable = false;
  formValid = false;
  confirmed = false;
  formattedAddress: string;
  width: string;
  isMobile = false;
  loading = false;
  customerPriceFrontend: number;
  questions: any[] = [];
  pricing: any;
  pool: any;
  customerFound: any;
  completeRegister = false;
  @ViewChild('stepper') private myStepper: MatStepper;
  constructor(
    public dialog: MatDialog,
    public firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    public utils: UtilitiesService,
    private commonService: CommonService,
    private firebaseService: FirestoreService,
    private gtmService: GoogleTagManagerService,
  ) { }
  readonly currentUser$ = this.afAuth.user.pipe(filter(objectExists));

  ngOnInit() {
    this.isMobile = this.utils.isMobile(this.detector);
    this.width = this.isMobile ? '100vw' : '50%';
  }

  async setPoolAddress() {
    this.loading = true;
    if (this.poolAddressComponent.addressForm.invalid || this.poolAddressComponent.addressForm.value.state == "--") {
      for (var i in this.poolAddressComponent.addressForm.controls) {
        this.poolAddressComponent.addressForm.controls[i].markAsDirty();
      }
      this.loading = false;
      return;
    }

    const gtmTag = {
      event: 'step_1_lead_address'
    };

    this.gtmService.pushTag(gtmTag);

    this.formattedAddress =
      this.poolAddressComponent.formattedAddress == undefined
        ? (this.formattedAddress = `${this.poolAddressComponent.addressForm.value.street},
                                   ${this.poolAddressComponent.addressForm.value.city},
                                   ${this.poolAddressComponent.addressForm.value.state},
                                   ${this.poolAddressComponent.addressForm.value.zipcode}`)
        : this.poolAddressComponent.formattedAddress;

    this.commonService.set('poolAddress', this.formattedAddress);

    this.isEditable = true;
    this.zipcode = this.poolAddressComponent.addressForm.value.zipcode;
    if (this.zipcode.toString().length == 5) {
      await this.firebaseService
        .getPrices(this.zipcode.toString())
        .then((result) => {
          if (result.data == false) {
            Swal.fire({
              title: 'Thanks you for interest',
              text:
                "We don't offer service in your area yet. Leave us your email and we will reach out to you when we are operating in your city!",
              input: 'email',
              inputAttributes: {
                autocapitalize: 'off',
                placeholder: 'Email',
              },
              showCancelButton: false,
              confirmButtonText: 'Notify Me!',
              showLoaderOnConfirm: true,
              preConfirm: (email) => {
                return this.firebaseService.saveIntent(
                  this.zipcode.toString(),
                  email
                );
              },
              allowOutsideClick: () => !Swal.isLoading(),
            });
            this.loading = false;
          } else {
            this.goForward();
            this.loading = false;
          }
        }).catch((err) => {
          this.loading = false;
        })
    } else {
      return;
    }
  }

  setPoolDetails() {

    const gtmTag = {
      event: 'step_2_lead_pool'
    };

    this.gtmService.pushTag(gtmTag);

    this.formValid = false;
    this.goForward();
  }

  async sendQuote() {
    if (this.personalInfoComponent.personalForm.invalid) {
      for (var i in this.personalInfoComponent.personalForm.controls) {
        this.personalInfoComponent.personalForm.controls[i].markAsDirty();
      }
      return;
    } else {
      this.disableInput();
      this.commonService.set(
        'customer',
        this.personalInfoComponent.personalForm.value
      );
    }

    const gtmTag = {
      event: 'step_3_lead_conversion'
    };

    this.gtmService.pushTag(gtmTag);

    this.loading = true;
    this.getPrice();
  }

  async getPrice() {
    let questions = [];
    for (const [key, value] of Object.entries(
      this.poolDetailComponent.poolDetailForm.value
    )) {
      questions.push({
        [key]: {
          'selected-options': value,
        },
      });
    }

    return await firebase
      .app()
      .functions()
      .httpsCallable('getPrice')({
        zipcode: this.poolAddressComponent.addressForm.value.zipcode,
        questions: questions,
        customer: this.personalInfoComponent.personalForm.value,
        pool: this.poolAddressComponent.addressForm.value,
      })
      .then((priceId) => {
        this.commonService.set('_', priceId.data);
        this.loading = false;
        this.confirmed = true;
        this.showButtonBack = false;
        this.goForward();
      })
      .catch((error) => {
        alertify.error(
          'Error occur trying to create a quote. Please try again.'
        );
        this.loading = false;
        return;
      });
  }

  enableInput() {
    for (var i in this.personalInfoComponent.personalForm.controls) {
      this.personalInfoComponent.personalForm.controls[i].enable();
    }
  }

  disableInput() {
    for (var i in this.personalInfoComponent.personalForm.controls) {
      this.personalInfoComponent.personalForm.controls[i].disable();
    }
  }
  goBack() {
    this.isEditable = true;
    this.myStepper.selected.completed = false;
    this.myStepper.previous();
  }

  goForward() {
    this.myStepper.selected.completed = true;
    this.myStepper.next();
  }

  hidenButton(prop) {
    if (!prop) {
      this.showButton = prop;
    }
  }
}
