import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-one-about',
  templateUrl: './section-one.component.html',
  styleUrls: ['./section-one.component.css']
})
export class SectionOneAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
