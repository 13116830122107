import { ModalContactComponent } from './../landing/modal-contact/modal-contact.component';
import { AboutUsRoutingModule } from './../about-us/about-us.routing.module';
import { FooterComponent } from './../landing/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressComponent } from './components/address/address.component';
import { SharedMaterialModule } from './shared-material.module';
import { ServiceDetailComponent } from './components/service-detail/service-detail.component';
import { RegisterComponent } from './components/register/register.component';
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalLayoutComponent } from './components/modal-layout/modal-layout.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ServiceConfirmedComponent } from './components/service-confirmed/service-confirmed.component';
import { CompleteRegistrationComponent } from './components/complete-registration/complete-registration.component';
import { OptionalInfoComponent } from './components/optional-info/optional-info.component';
import { PaymentComponent } from './components/payment/payment.component';
import { CustomDateAdapter } from './components/payment/custom-date-adapter';
import { DateAdapter } from '@angular/material/core';
import { GetquoteComponent } from './components/getquote/getquote.component';
const maskConfig: Partial<IConfig> = {
  validation: true,

};

@NgModule({
  imports: [
    CommonModule,
    SharedMaterialModule,
    GooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    HttpClientModule,
    AboutUsRoutingModule

  ],
  declarations: [
    AddressComponent,
    ModalLayoutComponent,
    PersonalInfoComponent,
    RegisterComponent,
    ServiceDetailComponent,
    ServiceConfirmedComponent,
    CompleteRegistrationComponent,
    OptionalInfoComponent,
    PaymentComponent,
    GetquoteComponent,
    FooterComponent,
    ModalContactComponent
  ],exports: [
    ModalLayoutComponent,
    AddressComponent,
    ModalLayoutComponent,
    PersonalInfoComponent,
    RegisterComponent,
    ServiceDetailComponent,
    ServiceConfirmedComponent,
    CompleteRegistrationComponent,
    PaymentComponent,
    OptionalInfoComponent,
    GetquoteComponent,
    FooterComponent,
    ModalContactComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ]
})
export class SharedModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('En');
  }
}
