<div class="section-two">
  <div class="part-one">
    <div class="content-part-one">
      <div class="cont">
        <span class="title"> Benefits of Partnering with PoolChief </span>
        <span class="d-none d-md-block"> <br /><br /> </span>
        <span class="d-block d-md-none">
          <br />
        </span>
        <div class="space-content">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="content">
                <span class="title2">Real accounts, not leads</span> <br />
                <span class="paragraph text-white">
                  We’re not in the business of selling leads. With PoolChief,
                  you get immediate access to pool cleaning accounts in your
                  area at no cost to you.
                </span>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="content">
                <span class="title2">More business, less hassle</span>
                <span class="paragraph text-white">
                  Add profitable pool cleaning accounts to your route without
                  all of the hassle. You don’t need to worry about marketing or
                  billing. We take care of everything.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="content">
                <span class="title2">Your time, your schedule</span> <br />
                <span class="paragraph text-white">
                  You work as an independent pool cleaning contractor. Just pick
                  the accounts that work for you
                </span>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="content">
                <span class="title2">Great customer support</span> <br />
                <span class="paragraph text-white">
                  If you run into any issues, our support team is here to help
                  you.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="part-two">
    <span class="font-primary-color title-modal text-center">
      PoolChief handles all the back-office <br />
      work for your pool cleaning business. <br />
      We take care of collecting payments, <br />
      customer support, marketing, and <br />
      accounting, so you can focus more on <br />
      servicing pools and making money.<br />
    </span>
  </div>
</div>
