<section class="footer">
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 col-sm-3">
        <div class="row" style="padding-bottom: 15px">
          <div class="col p-0">
            <img src="../../assets/aboutUs/logo.png" loading="lazy" alt="" class="logo-footer" routerLink="/"
              style="cursor: pointer" />
          </div>
        </div>
        <div class="row">
          <div class="col mt-2 mb-2 pl-sm-0">
            <p class="text-white paragraph-footer">
              PoolChief is an innovative <br />
              marketplace that leverages <br />
              technology to connect <br />
              swimming pool owners with the <br />
              best local pool cleaning pros. <br />
            </p>
          </div>
        </div>
        <div class="row d-none d-sm-block">
          <div class="col-12 p-0">
            <a href="https://www.facebook.com/PoolChief/" target="_blank"><img src="../../../assets/images/facebook.png"
                loading="lazy" alt="" class="icon-social" /></a>
            <a href="https://www.instagram.com/poolchief" target="_blank"><img src="../../../assets/images/intagram.png"
                loading="lazy" alt="" class="icon-social" /></a>
            <a href="https://www.youtube.com/channel/UCfO9nTHyj8PJvmjB3SrPNCg" target="_blank"><img
                src="../../../assets/images/youtube.png" loading="lazy" alt="" class="icon-social" /></a>
            <a href="https://twitter.com/GetPoolChief" target="_blank"><img src="../../../assets/images/twitter.png"
                loading="lazy" alt="" class="icon-social" /></a>
            <a href="https://www.linkedin.com/company/poolchief" target="_blank"><img
                src="../../../assets/images/linkedin.png" loading="lazy" alt="" class="icon-social" /></a>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-5">
        <div class="row">
          <div class="col-6 padding-footer-experimental px-0">
            <div class="row">
              <div class="col">
                <button class="btn link-footer" routerLink="/about">
                  About us
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn link-footer" [routerLink]="['/service']">
                  The Service
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn link-footer" [routerLink]="['/how-it-works']">
                  How it works
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn link-footer" [routerLink]="['/our-pool-pros']">
                  Our Pool Pros
                </button>
              </div>
            </div>
          </div>
          <div class="col-6 padding-footer-experimental px-0">
            <div class="row">
              <div class="col">
                <button class="btn link-footer" [routerLink]="['/why-poolchief']">
                  Why PoolChief
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn link-footer" (click)="contactUs()">
                  Contact us
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn link-footer" [routerLink]="['/faqs']">
                  FAQs
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn link-footer" routerLink="/become-pool-pro">
                  Become a Pool Pro
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-getquote></app-getquote>
    </div>

    <div class="row d-block d-sm-none">
      <div class="col-12">
        <a href="https://www.facebook.com/PoolChief/" target="_blank"><img src="../../../assets/images/facebook.png"
            loading="lazy" alt="" class="icon-social" /></a>
        <a href="https://www.instagram.com/poolchief" target="_blank"><img src="../../../assets/images/intagram.png"
            loading="lazy" alt="" class="icon-social" /></a>
        <a href="https://www.youtube.com/channel/UCfO9nTHyj8PJvmjB3SrPNCg" target="_blank"><img
            src="../../../assets/images/youtube.png" loading="lazy" alt="" class="icon-social" /></a>
        <a href="https://twitter.com/GetPoolChief" target="_blank"><img src="../../../assets/images/twitter.png"
            loading="lazy" alt="" class="icon-social" /></a>
        <a href="https://www.linkedin.com/company/poolchief" target="_blank"><img
            src="../../../assets/images/linkedin.png" loading="lazy" alt="" class="icon-social" /></a>
      </div>
    </div>

    <div class="row">
      <div class="col mt-3">
        <span class="text-white">© 2021 PoolChief All rights reserved &nbsp;&nbsp; |
          <button class="btn link-footer-term" routerLink="/terms">
            Terms of Service
          </button>
          |
          <button class="btn link-footer-term" routerLink="/privacy">
            Privacy Policy
          </button>
        </span>
      </div>
    </div>
  </div>
</section>
