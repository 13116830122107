<mat-horizontal-stepper linear #stepper *ngIf="!confirmed" class="steper-payment">
  <mat-step  [editable]="isEditable">
    <div class="scrollable-lg-content">
    <app-payment></app-payment>
    </div>
    <div class="row align-middle btn-modal-margin">
      <div class="col-6 col-md-4">
        <span class="">
          <small class="font-primary-color subtitle-modal">
            <span><mat-icon aria-hidden="false" class="font-primary-color" style="font-size: 8px; width: auto !important;">circle</mat-icon> </span>
             <span> Cancel at any time.</span>
          </small>
        </span>
      </div>
      <div class="col-6 col-md-5 temporal-padding">
        <span class="">
          <small class="font-primary-color subtitle-modal">
            <span><mat-icon aria-hidden="false" class="font-primary-color" style="font-size: 8px; width: auto !important;">circle</mat-icon> </span>
            <span> 100% satisfaction guarantee.</span>
          </small>
        </span>
      </div>
      <div class="col-12 col-md-3">
        <div [ngClass]="isMobile ? 'text-center reverse': 'text-right'">
          <button mat-raised-button [ngClass]="isMobile ? 'btn-block': 'btn-lg'" class="btn secondary-color text-white"  [class.spinner]="loading" [disabled]="loading" (click)="addSubscription()">Book</button>
        </div>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <app-optional-info></app-optional-info>
    <div class="text-right btn-modal-margin">
      <button mat-raised-button [ngClass]="isMobile ? 'btn-block': 'btn-lg'" class="btn secondary-color text-white"
        (click)="saveInfo()" [class.spinner]="loading" [disabled]="loading">Save</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
