<br>
<p> <span class="line-text title-modal font-primary-color">Let’s book your service</span></p>
<br>
<br>
<form [formGroup]="dateForm" id="payment-form" #cardInfo>
  <div class="row">
    <div class="col-12 col-md-6">
      <h6 class="font-primary-color title-modal">Select start week:</h6>
      <small class="font-primary-color subtitle-modal">Specific day of the week and time<br>of your service will be
        confirmed later.</small>
    </div>

    <div class="col-12 col-md-6 text-right">
      <span class="d-block d-md-none">
        <br>
      </span>
      <nz-date-picker formControlName="startDate" [nzFormat]="dateFormat" [nzAutoFocus]="true" nzMode="week"
        [(ngModel)]="date" (ngModelChange)="getWeek($event)" class="form-control" [nzInputReadOnly]="true"
        [nzDisabledDate]="disabledDate"></nz-date-picker>
      <span *ngIf="dateForm.get('startDate').invalid && (dateForm.get('startDate').dirty)">
        <span *ngIf="dateForm.get('startDate').errors.required" style="color: brown;">
          start week is required.
        </span>
      </span>
    </div>
  </div>
  <br>
  <span class="d-none d-md-block">
    <br>
    <br>
  </span>
  <p> <span class="line-text title-modal font-primary-color">Add your payment method</span></p>
  <div class="row text-center">
    <div class="col">
      <small class="font-primary-color subtitle-modal">You pay on first week of service.</small>
    </div>
  </div>
  <div class="mt-3">
    <div class="row">
      <div class="col">
        <div class="stripe-input">
          <div class="" style="width: 100% !important;">
            <div id="card-number"></div>
          </div>
        </div>
        <span *ngIf="validationErrors.cardNumber.isDirty && validationErrors.cardNumber.isEmpty" style="color: brown;">
          Credit / Debit Card Number is required.
        </span>
      </div>
    </div>
    <div class="row" #cardInfo>
      <div class="col-6 col-md-4">
        <div class="stripe-input">
          <div style="width: 100% !important;">
            <div id="card-cvc"></div>
          </div>
        </div>
        <span *ngIf="validationErrors.cardCvc.isDirty && validationErrors.cardCvc.isEmpty" style="color: brown;">
         CVC is required.
        </span>
      </div>
      <div class="col-6 col-md-4">
        <div class="stripe-input" #cardInfo>
          <div style="width: 100% !important;">
            <div id="card-exp"></div>
          </div>
        </div>
        <span *ngIf="validationErrors.cardExpiry.isDirty && validationErrors.cardExpiry.isEmpty" style="color: brown;">
          EXP is required.
        </span>
      </div>
      <div class="col-12 col-md-4" style="margin-top: 20px;">
        <div class="form-group">
          <input type="text" placeholder="Zip code" class="form-control" maxlength="5" formControlName="zipcode">
          <span *ngIf="dateForm.get('zipcode').invalid && (dateForm.get('zipcode').dirty)">
            <span *ngIf="dateForm.get('zipcode').errors.required" style="color: brown;">
              Zip code is required.
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="row space-pricing">

  <div class="col-8">
    <small class="font-primary-color subtitle-modal">
      You are booking All-inclusive pool cleaning<br>
      service. Provided weekly. Billed monthly.
    </small>
  </div>
  <div class="col-4 p-0">
    <h5 class="font-primary-color title-modal" *ngIf="!isMobile">${{monthly}}/<small
        class="font-primary-color subtitle-modal">Per Month</small></h5>
    <div class="row" *ngIf="isMobile">
      <div class="col-12">
        <h5 class="font-primary-color title-modal">${{monthly}}/</h5>
      </div>
      <div class="col-12">
        <small class="font-primary-color subtitle-modal">Per Month</small>
      </div>
    </div>
  </div>

</div>
