<div *ngIf="!completeRegister" style="padding: 25px 3px;">
 <div class="scrollable-lg-content">
  <div style="margin-left: 15px; margin-right: 15px;">
    <h5 class="font-primary-color title-modal">Here is your instant quote!</h5>
    <h6 class="font-primary-color subtitle-modal" style="padding-top: 16px; padding-bottom: 16px;">Pool Location: <br>
    {{formattedAddress}}
    </h6>

    <mat-divider class="mt-3 mb-3"></mat-divider>
  </div>

<div class="resume">
  <h6 class="font-primary-color title-modal" style="margin-left: 6px !important;">&nbsp;&nbsp; Regular all-inclusive Weekly Pool Cleaning</h6>
  <div class="resume-content">
    <div class="col-7">
      <ul class="resume-list font-primary-color">
        <li><mat-icon aria-hidden="false" class="font-secondary-color" style="font-weight: bold;">check</mat-icon> Brushing and Skimming</li>
        <li><mat-icon aria-hidden="false" class="font-secondary-color" style="font-weight: bold;">check</mat-icon> Vacuuming</li>
        <li><mat-icon aria-hidden="false" class="font-secondary-color" style="font-weight: bold;">check</mat-icon> Cleaning filter</li>
        <li><mat-icon aria-hidden="false" class="font-secondary-color" style="font-weight: bold;">check</mat-icon> Chemical balance</li>
        <li><mat-icon aria-hidden="false" class="font-secondary-color" style="font-weight: bold;">check</mat-icon> All chemicals include</li>
        <li><mat-icon aria-hidden="false" class="font-secondary-color" style="font-weight: bold;">check</mat-icon> Service performed every week</li>
      </ul>
    </div>
    <div class="col-5">
      <div class="price font-primary-color" *ngIf="!isMobile">
        <h3 class="font-primary-color title-modal">${{customerPriceFrontend}}/</h3>&nbsp;
        <div class="decoration">
          <small class="font-primary-color subtitle-modal" style="line-height: normal !important;">Per Service</small><br>
          <small>(Billed monthly)</small>
        </div>
      </div>
        <div class="price font-primary-color d-block row text-right" *ngIf="isMobile">
          <div class="col-12">
            <span class="font-primary-color title-modal" style="font-size: 30px;">${{customerPriceFrontend}}</span>&nbsp;
          </div>
         <div class="col-12">
          <div class="decoration">
            <small class="font-primary-color subtitle-modal" style="line-height: normal !important;">Per Service</small><br>
            <small>(Billed monthly)</small>
          </div>
         </div>
        </div>
    </div>

  </div>
  <div class="">
    <mat-divider style="margin-left: 25px; margin-right: 25px;"></mat-divider>
    <ul class="resume-list-2 font-primary-color mt-2">
      <li class="font-primary-color">100% satisfaction guarantee</li>
      <li class="font-primary-color">Vetted pool pro</li>
    </ul>
  </div>

</div>
</div>
<button class="btn secondary-color text-white btn-align" (click)="onNoClick()" style="font-size: 18px;">
 <span class="title-modal"> Hire Service Now</span> &nbsp;&nbsp; <mat-icon class="arrow-icon-modal">trending_flat</mat-icon>
</button>
</div>


<div class="col" *ngIf="completeRegister">
  <app-complete-registration></app-complete-registration>
</div>
