import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit {
  @Output() childToParent = new EventEmitter<any>();
  @Output() validateForm = new EventEmitter<boolean>();
  personalForm: FormGroup;
  public isPhoneNumberFocused: boolean = false;

  constructor(private fb: FormBuilder,
              public firestore: AngularFirestore,
              public afAuth: AngularFireAuth) {
               }

  ngOnInit(){
    this.personalForm = this.fb.group({
      firstName: ['',Validators.required],
      lastName: ['',Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    });
  }
}
