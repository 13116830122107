import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogData } from 'src/app/models/modal-data';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { CommonService } from 'src/app/utils/common.service';
import { UtilitiesService } from 'src/app/utils/utilities.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-service-confirmed',
  templateUrl: './service-confirmed.component.html',
  styleUrls: ['./service-confirmed.component.css']
})
export class ServiceConfirmedComponent implements OnInit {
  @Input() formattedAddress: string;
  customerPriceFrontend: number;
  @Output() showButtonBack = new EventEmitter<boolean>();
  data: DialogData;
  completeRegister = false;
  detector = navigator.userAgent;
  isMobile: boolean;
  constructor(
    public dialogRef: MatDialogRef<ModalLayoutComponent>,
    private commonService: CommonService,
    private utils: UtilitiesService,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit() {
    this.customerPriceFrontend = this.commonService.get("_").weekly
    this.isMobile = this.utils.isMobile(this.detector);
  }

  onNoClick(): void {

    const gtmTag = {
      event: 'step_1_prospect_quote'
    };

    this.gtmService.pushTag(gtmTag);

    this.data = { zipCode: 0, isMobile: true, completedRegister: true, basePrice: 0 }
    //this.dialogRef.close(this.data);
    this.completeRegister = true;
    this.showButtonBack.emit(false);
  }

}
