import { UtilitiesService } from 'src/app/utils/utilities.service';
import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  detector = navigator.userAgent;
  opened:boolean;
  isMobile: boolean;
  constructor(private utils: UtilitiesService) { }

  ngOnInit() {
    this.isMobile = this.utils.isMobile(this.detector);
    this.opened = !this.isMobile;
  }

  toogleSideBar(ev){
   this.opened = ev;
  }
}
