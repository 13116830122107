import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { } from 'googlemaps';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilitiesService } from '../utils/utilities.service';
import { ModalLayoutComponent } from '../shared/components/modal-layout/modal-layout.component';
import { ModalContactComponent } from '../landing/modal-contact/modal-contact.component';
import { MetaService } from '../services/meta/meta.service';
import { Meta, Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  zipCode: number;
  detector = navigator.userAgent;
  geocoder: any;
  formattedAddress: string;
  width: string;
  height: string;
  isMobile: boolean;
  zipCodeForm: FormGroup;
  basePrice: number;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private utils: UtilitiesService,
    private meta: Meta,
    private title: Title,
    private metaService: MetaService,
    private gtmService: GoogleTagManagerService,
  ) {
    this.meta.updateTag(
      { name: 'description', content: this.metaService.aboutUsDescription() }
    );
    this.title.setTitle(this.metaService.aboutUsTitle());
  }

  ngOnInit(): void {
    this.width = this.utils.isMobile(this.detector) ? '100vw' : '600px';
    this.height = this.utils.isMobile(this.detector) ? '90vh' : '550px';
    this.isMobile = this.utils.isMobile(this.detector);
    this.zipCodeForm = this.fb.group({
      zipcode: [''],
      zipcodeFooter: ['']
    });
  }

  openModal(width: string, height: string) {
    const gtmTag = {
      event: 'click_on_see_my_price',
      zipcode: this.zipCodeForm.value.zipcode
    };

    this.gtmService.pushTag(gtmTag);

    const dialogRef = this.dialog.open(ModalLayoutComponent, {
      maxWidth: width,
      minHeight: 'calc(90% - 90px)',
      width: '100%',
      height: 'auto',
      data: {
        zipCode: this.zipCodeForm.value.zipcode,
        isMobile: this.utils.isMobile(this.detector),
        completedRegister: false,
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      const value = result === undefined ? false : result.completedRegister;
      if (value) {
        this.openModalCompleteRegister(width, height);
      }
    });
  }

  openModalCompleteRegister(width: string, height: string) {
    const gtmTag = {
      event: 'click_on_see_my_price',
      zipcode: this.zipCodeForm.value.zipcode
    };

    this.gtmService.pushTag(gtmTag);

    const dialogRef = this.dialog.open(ModalLayoutComponent, {
      maxWidth: width,
      minHeight: 'calc(100% - 90px)',
      width: '100%',
      height: 'auto',
      data: {
        zipCode: this.zipCodeForm.value.zipcode,
        isMobile: this.utils.isMobile(this.detector),
        completedRegister: true,
        basePrice: this.basePrice
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  openRegisterModal() {
    this.openModal(this.width, this.height);
  }

  openRegisterModalBanner() {
    this.openModal(this.width, this.height);
  }

  contactUs() {
    this.openModalContact(this.width, this.height);
  }

  openModalContact(width: string, height: string) {
    this.dialog.open(ModalContactComponent, {
      panelClass: 'contact-dialog-container',
      maxWidth: width,
      minHeight: '614px',
      width: '100%',
      height: 'auto',
      disableClose: true
    });
  }


}
