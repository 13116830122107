import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-four',
  templateUrl: './section-four.component.html',
  styleUrls: ['./section-four.component.css']
})
export class SectionFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
