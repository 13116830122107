import { CommonService } from 'src/app/utils/common.service';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { } from 'googlemaps';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, OnDestroy {
  @Input() zipcode: number;
  @Output() childToParent = new EventEmitter<any>();
  @Output() validateForm = new EventEmitter<boolean>();
  @Output() formattedAddressToParent = new EventEmitter<string>();

  map: any;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  address: Object;
  geocoder: any;
  options = {
    types: [],
    componentRestrictions: { country: 'US' },
    bounds: null,
    fields: [],
    strictBounds: false,
    origin: null,
  }
  states: any = [];
  addressForm: FormGroup;
  savedForm: any;
  formattedAddress: string;
  constructor(private fb: FormBuilder,
      public afAuth: AngularFireAuth,
      private httpClient: HttpClient,
      private commonService: CommonService) {
   }

  ngOnInit() {

    if(this.zipcode) {
      this.codeAddress(this.zipcode);
    }

    this.addressForm = this.fb.group({
      street: ['',Validators.required],
      apartment: [''],
      city: ['',Validators.required],
      state: ['--',Validators.required],
      zipcode: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{5}$")]]
    });

    this.savedForm = this.commonService.get("savedForm");
    this.validateForm.emit(true);
    if(this.savedForm) {
      this.addressForm.patchValue(
       this.savedForm
      )
    }

    this.httpClient.get("assets/states_titlecase.json").subscribe(data =>{
      this.states = data;
    })
  }

  ngOnDestroy() {
    this.commonService.set("savedForm",this.addressForm.value);
  }

  public handleAddressChange(address: any) {
    this.getAddress(address);
    console.log(address);
  }

  getAddress(place: object) {

    this.commonService.remove("savedForm")
    this.addressForm.patchValue(
      {
        street: !this.commonService.getStreetNumber(place) ? "" :  this.commonService.getStreetNumber(place) + " " +this.commonService.getStreet(place),
        state: this.commonService.getDistrict(place),
        zipcode:  this.codeAddress(this.commonService.getPostCode(place))
      }
    )
    this.formattedAddress = place["formatted_address"]
  }

  onEnter() {
    this.commonService.codeAddress(this.addressForm.value.postcode);
  }

  codeAddress(postcode) {
    this.commonService.remove("savedForm");
    this.geocoder = new google.maps.Geocoder();
    this.geocoder.geocode({ 'address': postcode}, (results, status) => {
     if (status == google.maps.GeocoderStatus.OK) {
       this.addressForm.patchValue(
         {
           city: results[0]["address_components"][1]["long_name"],
           state: results[0]["address_components"][3]["short_name"],
           zipcode: results[0]["address_components"][0]["short_name"]
         }
       )
     } else {
       return
     }
     });
   }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
