import { PrivacyComponent } from './../privacy/privacy.component';
import { TermsComponent } from './../terms/terms.component';
import { BecomeAPoolProComponent } from './../become-a-pool-pro/become-a-pool-pro.component';
import { AboutUsComponent } from './../about-us/about-us.component';
import { LandingComponent } from './../landing/landing.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'home/:page',
    component: LandingComponent
  },
  {
    path: 'about',
    loadChildren: () => import('../about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'become-pool-pro',
    component: BecomeAPoolProComponent
  },
  {
    path: ':page',
    component: LandingComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule { }
