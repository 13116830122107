<section class="header">
  <div class="row">
    <div class="col logo-button-space">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <img
              src="../../assets/aboutUs/logo.png"
              class="img-navbar-mobile"
              alt=""
              width="175"
              routerLink="/"
              style="cursor: pointer"
            />
          </div>
          <div class="col-6 text-right d-none d-md-block">
            <div>
              <button
                class="btn btn-template"
                (click)="become()"
                style="padding: 10px 30px; background-color: #fff"
              >
                <span class="font-secondary-color">Apply Now</span> &nbsp;
                <mat-icon class="font-secondary-color">trending_flat</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-about container">
    <div class="row text-center">
      <div class="col-12">
        <h1 class="primary-title text-white">
          Grow your pool <br />
          cleaning business
        </h1>
        <br />
        <span
          class="paragraph text-center"
          style="line-height: normal; margin-top: 16px"
          >Join us today and start adding profitable <br />
          accounts to your pool route for free.</span
        >
      </div>
      <div class="col-12">
        <button
          class="btn btn-header-become"
          style="padding: 15px 30px"
          (click)="become()"
        >
          Become a Pool Pro
        </button>
      </div>
    </div>
  </div>
</section>

<app-section-one-become></app-section-one-become>
<app-section-two-become></app-section-two-become>

<app-section-three-become></app-section-three-become>
<app-section-four-become></app-section-four-become>

<div class="row p-0 m-0" [ngClass]="isMobile ? '' : 'd-none'">
  <div class="col p-0 m-0">
    <img
      src="../../assets/assets_WebP/images/img-banner-mobile.webp"
      loading="lazy"
      class="img-fluid w-100"
    />
  </div>
</div>

<section class="banner-second">
  <div class="other">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-8" [ngClass]="isMobile ? 'text-center' : ''">
          <h2 class="title-modal text-white">How to Apply?</h2>
          <br />
          <span
            class="text-white paragraph subtitle-modal"
            [ngClass]="isMobile ? 'text-center' : ''"
          >
            It’s simple. Just click on “Apply Now” and fill out the<br />
            application form. Our onboarding team will contact you<br />
            to continue the process.
          </span>
          <br /><br />
          <div class="col-12 p-0" [ngClass]="isMobile ? 'text-center' : ''">
            <button
              class="btn btn-banner title-modal btn-banner-size"
              (click)="become()"
              style="
                display: inline-flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: center;
              "
            >
              <span class="font-secondary-color">Apply Now</span> &nbsp;
              <mat-icon class="font-secondary-color">trending_flat</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
