<mat-horizontal-stepper linear #stepper labelPosition="bottom" *ngIf="!confirmed">
  <mat-step style=" background-color: white;">
    <ng-template matStepperIcon="edit" let-index="index">
      {{index + 1}}
    </ng-template>
    <app-address [zipcode]="zipcode"></app-address>

    <div class="btn-modal-margin" [ngClass]="isMobile ? 'text-center reverse': 'text-right'">
      <button mat-raised-button [ngClass]="isMobile ? 'btn-block': 'btn-lg'"
        class="btn btn-lg secondary-color text-white" (click)="setPoolAddress()" [class.spinner]="loading"
        [disabled]="loading">Next</button>
    </div>
  </mat-step>

  <mat-step>

    <app-service-detail></app-service-detail>
    <div class="btn-modal-margin" [ngClass]="isMobile ? 'text-center reverse': 'text-right'">
      <button mat-button class="btn font-secondary-color btn-lg" (click)="goBack()">Back</button>
      <button mat-raised-button [ngClass]="isMobile ? 'btn-block': 'btn-lg'"
        class="btn secondary-color btn-lg text-white" (click)="setPoolDetails()">Next</button>
    </div>
  </mat-step>
  <mat-step>
    <app-personal-info>
    </app-personal-info>
    <div class="btn-modal-margin" [ngClass]="isMobile ? 'text-center reverse': 'text-right'">
      <button mat-button class="btn font-secondary-color btn-lg" (click)="goBack()">Back</button>

      <button mat-raised-button [ngClass]="isMobile ? 'btn-block': 'btn-lg'" [class.spinner]="loading"
        [disabled]="loading" class="btn secondary-color btn-lg text-white" (click)="sendQuote()">See my quote
        now</button>
    </div>
  </mat-step>

  <ng-template matStepperIcon="chat">
    <mat-icon>forum</mat-icon>
  </ng-template>


</mat-horizontal-stepper>

<div class="service-resume" *ngIf="confirmed">
  <app-service-confirmed [formattedAddress]="formattedAddress" (showButtonBack)="hidenButton($event)">
  </app-service-confirmed>
  <div class="row text-center btn-back-confirmed" *ngIf="showButton">
    <div class="col">
      <button mat-button class="btn font-secondary-color btn-lg" (click)="confirmed = !confirmed">Back</button>
    </div>
  </div>
</div>
