<section class="page-six">
  <div class="container">
    <div class="row">
      <div class="col mb-3">
        <h3 class="font-primary-color title">Frequently Asked Questions</h3>
      </div>
    </div>
    <div class="row">
      <div class="col mt-3">
        <mat-accordion>
          <mat-expansion-panel
            class="mat-elevation-z0"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="custom-class">
              <mat-panel-title class="title-question">
                Is PoolChief Worth It?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="paragraph"
              >At PoolChief, we make sure you are paying a reasonable price
              based on the local market but without the hassle of calling,
              waiting, and evaluating multiple quotes; we let technology do the
              hard work so you can enjoy your pool.</p
            >
          </mat-expansion-panel>
          <mat-expansion-panel
            class="mat-elevation-z0"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="custom-class">
              <mat-panel-title class="title-question">
                What about pricing? How does it work?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="paragraph">
              As a homeowner, you pay the price as advertised in our quote; no
              hidden fees and completely transparent. PoolChief keeps a small
              fee and the majority goes directly to the pool pro.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel
            class="mat-elevation-z0"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="custom-class">
              <mat-panel-title class="title-question">
                Am I really getting the best price for pool cleaning?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="paragraph">
              We partner with local contractors to determine a fair price that
              is right for you. We test and evaluate the market using technology
              to come up with estimates that are reasonable and attractive for
              you and our pool pros.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel
            class="mat-elevation-z0"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="custom-class">
              <mat-panel-title class="title-question">
                What do we mean by a 100% satisfaction guarantee?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p class="paragraph">
              Just let us know within 48 hours after the pool pro serviced your
              pool if you are not satisfied. We'll send the pro back to fix the
              issue free of charge. If it's not resolved, we'll fully refund
              your money. No questions asked!
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</section>
