<div class="scrollable-lg-content">
<div class="row">
  <div class="col p-0 m-0">
    <h5 class="font-primary-color title-modal">Details about your pool</h5>
    <h6 class="font-primary-color subtitle-modal">This will help us give you the best possible quote:</h6>
  </div>
</div>
<div class="details-section mt-4">
  <form [formGroup]="poolDetailForm">
    <div class="row">
      <div class="question-box">
        <div class="col-12 col-md-6 p-0 m-0">
          <h6 class="font-primary-color question-modal">1. Pool type</h6>
        </div>
        <div class="col-12 col-md-6">
          <mat-radio-group required formControlName="Q1">
            <div class="row">
              <div class="col-6">
                <mat-radio-button checked value="A">In-ground</mat-radio-button>
              </div>
              <div class="col-6">
                <mat-radio-button value="B">Above ground</mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="question-box">
        <div class="col-12 col-md-6 p-0 m-0">
          <h6 class="font-primary-color question-modal">2. Pool size <small class="question-small">(Large is 15,000+
              gal)</small></h6>
        </div>
        <div class="col-12 col-md-6">
          <mat-radio-group formControlName="Q2">
            <div class="row">
              <div class="col-6">
                <mat-radio-button checked value="A">Regular</mat-radio-button>
              </div>
              <div class="col-6">
                <mat-radio-button value="B">Large</mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="question-box">
        <div class="col-12 col-md-6 p-0 m-0">
          <h6 class="font-primary-color question-modal">3. Is it covered? <small class="question-small">(screen or
              roof)</small></h6>
        </div>
        <div class="col-12 col-md-6">
          <mat-radio-group formControlName="Q3">
            <div class="row">
              <div class="col-6">
                <mat-radio-button checked value="A">Yes</mat-radio-button>
              </div>
              <div class="col-6">
                <mat-radio-button value="B">No</mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="question-box">
        <div class="col-12 col-md-6 p-0 m-0">
          <h6 class="font-primary-color question-modal">4. Water sanitation <small class="question-small">(if
              known)</small></h6>
        </div>
        <div class="col-12 col-md-6">
          <mat-radio-group formControlName="Q4">
            <div class="row">
              <div class="col-6">
                <mat-radio-button checked value="A">Chlorine</mat-radio-button>
              </div>
              <div class="col-6">
                <mat-radio-button value="B"><span class="wrap-mat-radio-label">Salt Water</span></mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="question-box">
        <div class="col-12 col-md-6 p-0 m-0">
          <h6 class="font-primary-color question-modal">5. Filter type <small class="question-small">(if known)</small>
          </h6>
        </div>
        <div class="col-12 col-md-6">
          <mat-radio-group formControlName="Q5">
            <div class="row">
              <div class="col-6">
                <mat-radio-button checked value="A"><span class="wrap-mat-radio-label">Cartridge</span>
                </mat-radio-button>
              </div>
              <div class="col-6">
                <mat-radio-button value="B"><span class="wrap-mat-radio-label">Sand / D.E.</span></mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="question-box">
        <div class="col-12 col-md-6 p-0 m-0">
          <h6 class="font-primary-color question-modal">6. Water heater? <small class="question-small">(if
              known)</small></h6>
        </div>
        <div class="col-12 col-md-6">
          <mat-radio-group formControlName="Q6">
            <div class="row">
              <div class="col-6">
                <mat-radio-button value="B">No</mat-radio-button>
              </div>
              <div class="col-6">
                <mat-radio-button checked value="A">Yes</mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="question-box">
        <div class="col-12 col-md-6 p-0 m-0">
          <h6 class="font-primary-color question-modal">7. Is your pool water green?</h6>
        </div>
        <div class="col-12 col-md-6">
          <mat-radio-group formControlName="Q7">
            <div class="row">
              <div class="col-6">
                <mat-radio-button value="B">No</mat-radio-button>
              </div>
              <div class="col-6">
                <mat-radio-button checked value="A">Yes</mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </form>
</div>
</div>