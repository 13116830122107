<div class="row">
  <div class="col-12 col-md-4">
    <img src="../../../assets/assets_WebP/images/contact-image.webp" loading="lazy" alt="" class="image-contact d-none d-md-block">
    <img src="../../../assets/assets_WebP/modal-contact-mobile.webp"  loading="lazy" alt="" class="image-contact d-block d-md-none">
  </div>
  <div class="col-12 col-md-8">
    <div class="row text-right">
      <div class="col">
        <button class="btn font-secondary-color" (click)="onNoClick()"><mat-icon class="font-secondary-color" style="font-weight: bold; font-size: 1.7rem;">close</mat-icon></button>
      </div>
    </div>
    <div class="p-5">
      <div class="row">
        <div class="col">
          <h3 class="font-primary-color title-modal">
            Contact Us
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h6 class="font-primary-color subtitle-modal">
            Thank you for contacting us, complete the form and we will contact you as soon as possible.
          </h6>
        </div>
      </div>
      <form [formGroup]="contactUsForm">
      <div class="row">
        <div class="col mt-5">
          <div class="form-group">
            <input type="text" placeholder="Full Name" class="form-control" formControlName="full_name">
            <span *ngIf="contactUsForm.get('full_name').invalid && (contactUsForm.get('full_name').dirty)">
              <span *ngIf="contactUsForm.get('full_name').errors.required" style="color: brown;">
                Full Name is required.
              </span>
            </span>
          </div>
          <div class="form-group">
            <input type="text" placeholder="Email" class="form-control" formControlName="email">
            <span *ngIf="contactUsForm.get('email').invalid && (contactUsForm.get('email').dirty)">
              <span *ngIf="contactUsForm.get('email').errors.required" style="color: brown;">
                Email is required.
              </span>
            </span>
          </div>
          <div class="form-group">
            <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" class="form-control" placeholder="Pool Address (optional)" formControlName="pool_address"/>
          </div>
          <div class="form-group">
            <textarea name="" placeholder="Explain us what the issue is about" class="form-control" formControlName="full_description"></textarea>
            <span *ngIf="contactUsForm.get('full_description').invalid && (contactUsForm.get('full_description').dirty)">
              <span *ngIf="contactUsForm.get('full_description').errors.required" style="color: brown;">
                Issue is required.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-3">
          <button  mat-raised-button [class.spinner]="loading" [disabled]="loading" class="btn secondary-color text-white btn-send" type="submit"  (click)="send()">SEND</button>
        </div>
      </div>
    </form>
    </div>
  </div>

</div>
