import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(public firestore: AngularFirestore) {}

  async addPoolAdditionalDetails(priceId, instructions) {
    return await firebase.app().functions().httpsCallable('addPoolAdditionalDetails')({"priceId": priceId,"instructions": instructions});
  }

  async getPrices(zipcode: string) {
   return await firebase.app().functions().httpsCallable('checkAvailability')({"zipcode": zipcode});
  }

  async saveIntent(zipcode: string, email) {
   await firebase.app().functions().httpsCallable('userIntent')({
      "zipcode": zipcode,
      "email": email
    });
  }

  async sendMail(customerData,templateName) {
    await firebase.app().functions().httpsCallable('sendMail')({
     customerData,
     templateName
    });
  }
}
