<section class="header">
  <div class="row">
    <div class="col logo-button-space">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <img src="../../assets/aboutUs/logo.png" class="img-navbar-mobile" width="175" routerLink="/"
              style="cursor: pointer" />
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-template" (click)="openRegisterModal()">
              Get a Quote
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-about container">
    <div class="row text-center">
      <div class="col">
        <h1 class="title-modal text-white" style="font-size: 50px !important">
          About us
        </h1>
        <br />
        <span class="paragraph text-center subtitle-modal" style="line-height: normal; margin-top: 16px">
          PoolChief is an innovative marketplace that leverages <br />
          technology to connect swimming pool owners with the <br />
          best local pool cleaning pros.
        </span>
      </div>
    </div>
  </div>
</section>

<app-section-one-about></app-section-one-about>
<app-section-two-about></app-section-two-about>
<app-section-tree-about></app-section-tree-about>
<app-section-four-about></app-section-four-about>

<div class="row p-0 m-0" [ngClass]="isMobile ? 'd-md-block' : 'd-none'">
  <div class="col p-0 m-0">
    <img src="../../assets/assets_WebP/images/img-banner-mobile.webp" loading="lazy" class="img-fluid w-100" />
  </div>
</div>

<section class="banner-second">
  <div class="other">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-7" [ngClass]="isMobile ? 'text-center' : ''">
          <h1 class="text-white title-second-banner">
            Hire the best pool cleaning near you. Fully vetted pool pros.
            Affordable. Fast Online Ordering
          </h1>
          <br /><br />
          <button class="btn btn-banner title-modal btn-banner-size" (click)="openRegisterModalBanner()">
            Get a Quote
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
