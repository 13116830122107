<div class="col-12 col-sm-4">
  <div class="card footer-card">
    <div class="row text-center">
      <div class="col">
        <h5 class="font-primary-color title-modal">Get a one minute quote.</h5>
      </div>
    </div>

    <div class="row text-center">
      <div class="col" style="margin-top: 16px; margin-bottom: 20px">
        <div>
          <span
            class="font-primary-color subtitle-modal"
            style="font-size: 18px; line-height: normal"
            >Fast Online Ordering,
            <br />
            100% Satisfaction Guarantee
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <form [formGroup]="zipCodeForm">
          <input
            type="text"
            placeholder="Your zip code"
            class="form-control padding-card-inputs"
            maxlength="5"
            formControlName="zipcode"
            (keydown.enter)="getLocation()"
            (keypress)="numberOnly($event)"
          />
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button
          class="btn get-quote-footer btn-block btn-lg padding-card-inputs"
          (click)="getLocation()"
        >
          <span> See my price</span> &nbsp;<mat-icon class=""
            >trending_flat</mat-icon
          >
        </button>
      </div>
    </div>
  </div>
</div>
