import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { PaymentComponent } from '../payment/payment.component';
import { MatStepper } from '@angular/material/stepper';
import {
  UtilitiesService,
} from 'src/app/utils/utilities.service';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { OptionalInfoComponent } from '../optional-info/optional-info.component';
import { CommonService } from 'src/app/utils/common.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import * as alertify from 'alertifyjs';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.css'],
})
export class CompleteRegistrationComponent implements OnInit {
  poolId: string;
  @ViewChild(PaymentComponent) paymentComponent: PaymentComponent;
  @ViewChild(OptionalInfoComponent)
  optionalInfoComponent: OptionalInfoComponent;
  @ViewChild('stepper') private myStepper: MatStepper;
  @Output() childToParent = new EventEmitter<any>();
  bookingDetail: any;
  isEditable = true;
  confirmed = false;
  detector = navigator.userAgent;
  isMobile = false;
  loading = false;
  subscription = false;
  servicePrice: number;
  service: string;
  instructions: any;
  userInfo: any;
  constructor(
    public utils: UtilitiesService,
    public afAuth: AngularFireAuth,
    private commonService: CommonService,
    private firebaseService: FirestoreService,
    public dialogRef: MatDialogRef<ModalLayoutComponent>,
    private gtmService: GoogleTagManagerService,
  ) { }

  ngOnInit() {
    this.isMobile = this.utils.isMobile(this.detector);
  }

  getBooking(value) {
    this.bookingDetail = value;
  }

  async addPoolAdditionalDetails() {

  }

  goBack() {
    this.myStepper.previous();
  }

  private goForward() {
    this.myStepper.next();
    this.commonService.serviceBooked = true;
  }

  async saveInfo() {
    this.loading = true;

    const gtmTag = {
      event: 'step_3_client_details'
    };

    this.gtmService.pushTag(gtmTag);

    this.firebaseService.addPoolAdditionalDetails(this.commonService.get('_').priceId,
      this.optionalInfoComponent.aditionalInfoForm.value)
      .then((result) => {
        if (result.data) {
          this.loading = false;
          this.confirmed = true;
          this.dialogRef.close();
          alertify.success('Subscription created successfully!');
        } else {
          this.loading = false;
          alertify.error('Error occurred trying to save additional details.');
        }
      });
  }

  async addSubscription() {
    if (!this.paymentComponent.formValid || this.paymentComponent.dateForm.invalid) {
      for (const validation in this.paymentComponent.validationErrors) {
        this.paymentComponent.validationErrors[validation].isDirty = true;
      }
      for (var i in this.paymentComponent.dateForm.controls) {
        this.paymentComponent.dateForm.controls[i].markAsDirty();
      }
      return;
    }
    this.loading = true;

    if (!this.paymentComponent.formValid) {
      return;
    }

    this.userInfo = this.commonService.get('customer');

    let paymentMethod;
    await stripe
      .createPaymentMethod({
        type: 'card',
        card: this.paymentComponent.card,
        billing_details: {
          address: {
            postal_code: this.paymentComponent.dateForm.value.zipcode,
          },
          email: this.userInfo.email,
          name: this.userInfo.firstName + " " + this.userInfo.lastName,
          phone: this.userInfo.phoneNumber,
        },
      })
      .then((result) => {
        if (result.error) {
          console.log("Stripe failed to create payment method:", result.error);
          if (result.error.type == "card_error") {
            alertify.error('There was a problem with your card. Make sure the information you input is correct or try a different card.');
          } else {
            this.reportSubscriptionError("Non card declined Stripe error", result.error);
          }
          this.loading = false;
        }

        paymentMethod = result.paymentMethod;
      });

    if (!this.loading) {
      return;
    }

    await firebase
      .app()
      .functions()
      .httpsCallable('subscriptionSchedule')({
        priceId: this.commonService.get('_').priceId,
        startDate: this.paymentComponent.startDate,
        paymentMethodId: paymentMethod.id,
      })
      .then((result) => {
        if (result.data != null) {
          this.reportSubscriptionError("Error in result from subscriptionSchedule", result.data);
          this.dialogRef.close();
          return;
        }
        this.loading = false;
        this.goForward();

        const gtmTag = {
          event: 'step_2_prospect_booked'
        };

        this.gtmService.pushTag(gtmTag);

      })
      .catch((error) => {
        this.reportSubscriptionError("Error calling subscriptionSchedule", error);
      });
  }

  reportSubscriptionError(errorMessage, error) {
    console.error(errorMessage, error);
    alertify.error('Error occurred trying to create a subscription. Please try again.');
    this.loading = false;
  }
}
