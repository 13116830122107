import { ModalBecomeComponent } from './modal-become/modal-become.component';
import { ModalContactComponent } from './modal-contact/modal-contact.component';
import { UtilitiesService } from './../utils/utilities.service';
import { ModalLayoutComponent } from 'src/app/shared/components/modal-layout/modal-layout.component';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { } from 'googlemaps';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressComponent } from '../shared/components/address/address.component';
import { MetaService } from '../services/meta/meta.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  @ViewChild(AddressComponent) poolAddressComponent: AddressComponent;
  zipCode: number;
  detector = navigator.userAgent;
  geocoder: any;
  formattedAddress: string;
  width: string;
  height: string;
  basePrice: number;
  scrollUp = false;
  isMobile: boolean;
  @ViewChild("service") service: ElementRef;
  page = "";
  loading = false;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private utils: UtilitiesService,
    private activeRoute: ActivatedRoute,
    private meta: Meta,
    private title: Title,
    private metaService: MetaService,
    private gtmService: GoogleTagManagerService,
  ) {
    this.meta.updateTag(
      { name: 'description', content: this.metaService.landingDescription() }
    );
    this.title.setTitle(this.metaService.landingTitle());
  }
  zipCodeForm: FormGroup;

  ngOnInit() {
    this.width = this.utils.isMobile(this.detector) ? '100vw' : '600px';
    this.height = this.utils.isMobile(this.detector) ? '70vh' : '550px';
    this.isMobile = this.utils.isMobile(this.detector);
    this.zipCodeForm = this.fb.group({
      zipcode: [''],
      zipcodeFooter: ['']
    });
    if (this.activeRoute.snapshot.params.page) {
      const route: HTMLElement = document.getElementById(this.activeRoute.snapshot.params.page);
      route.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  openModal(width: string, height: string) {
    const gtmTag = {
      event: 'click_on_see_my_price',
      zipcode: this.zipCodeForm.value.zipcode
    };

    this.gtmService.pushTag(gtmTag);

    const dialogRef = this.dialog.open(ModalLayoutComponent, {
      maxWidth: width,
      minHeight: 'calc(90% - 90px)',
      width: '100%',
      height: 'auto',
      data: {
        zipCode: this.zipCodeForm.value.zipcode,
        isMobile: this.utils.isMobile(this.detector),
        completedRegister: false,
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      dialogRef.afterClosed().subscribe((result) => {
      });
    });
  }

  openModalCompleteRegister(width: string, height: string) {
    const gtmTag = {
      event: 'click_on_see_my_price',
      zipcode: this.zipCodeForm.value.zipcode
    };

    this.gtmService.pushTag(gtmTag);

    const dialogRef = this.dialog.open(ModalLayoutComponent, {
      maxWidth: width,
      minHeight: 'calc(90% - 90px)',
      width: '100%',
      height: 'auto',
      data: {
        zipCode: this.zipCodeForm.value.zipcode,
        isMobile: this.utils.isMobile(this.detector),
        completedRegister: true,
        basePrice: this.basePrice
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  async getLocation() {

    this.openModal(this.width, this.height);
  }

  openRegisterModal() {
    this.openModal(this.width, this.height);
  }

  scrollToElement(element): void {
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  contactUs() {
    this.openModalContact(this.width, this.height);
  }

  become() {
    this.openModalBecome(this.width, this.height);
  }

  openModalContact(width: string, height: string) {
    this.dialog.open(ModalContactComponent, {
      panelClass: 'contact-dialog-container',
      maxWidth: width,
      minHeight: '614px',
      width: '100%',
      height: 'auto',
      disableClose: true
    });
  }

  openModalBecome(width: string, height: string) {
    this.dialog.open(ModalBecomeComponent, {
      maxWidth: width,
      maxHeight: height,
      width: '100%',
      disableClose: true
    });
  }
}
