<mat-drawer-container hasBackdrop="true">
  <mat-drawer #drawer mode="over">
    <div class="row text-center">
      <div class="col text-center">
        <a class="navbar-brand" href="#" routerLink="/">
          <img src="./../../assets/aboutUs/logo.png" alt="" width="175px" />
        </a>
        <div class="row text-center">
          <div class="col mt-5">
            <ul class="navbar-nav text-center">
              <li class="nav-item d-block">
                <button class="btn btn-link btn-block nav-link text-white" [routerLink]="['/service']">
                  The service
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link text-white" [routerLink]="['/how-it-works']">
                  How it works
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link text-white" [routerLink]="['/our-pool-pros']">
                  Our Pool Pros
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link text-white" [routerLink]="['/why-poolchief']">
                  Why PoolChief
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link text-white" [routerLink]="['/faqs']">
                  FAQs
                </button>
              </li>
              <div class="row text-center">
                <div class="col mt-5">
                  <li class="nav-item">
                    <a class="nav-link secondary-color btn-get-quote btn" (click)="drawer.toggle(); getLocation()">Get a
                      Quote</a>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content (scroll)="onScroll($event)">
    <nav class="navbar navbar-expand-lg navbar-light" [ngClass]="isMobile ? 'mobile-navbar' : 'container'" #up>
      <div class="navbar-brand p-0 mt-2" routerLink="/">
        <img src="./../../assets/images/brand.png" alt="" width="175px" class="img-logo" />
      </div>
      <button class="navbar-toggler btn-link" type="button" (click)="drawer.toggle()">
        <mat-icon class="font-primary-color">menu</mat-icon>
      </button>

      <div class="collapse navbar-collapse navbar-align-g d-none d-lg-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <button class="btn btn-link nav-link" [routerLink]="['/service']">
              The service
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" [routerLink]="['/how-it-works']">
              How it works
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" [routerLink]="['/our-pool-pros']">
              Our Pool Pros
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" [routerLink]="['/why-poolchief']">
              Why PoolChief
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" [routerLink]="['/faqs']">
              FAQs
            </button>
          </li>
          <li class="nav-item ml-4">
            <a class="nav-link primary-color btn-get-quote btn btn-get-quote-bar" (click)="getLocation()">Get a
              Quote</a>
          </li>
        </ul>
      </div>
    </nav>

    <section class="terms-space container">
      <div class="row text-center">
        <div class="col-12 mt-5">
          <h3 class="title font-primary-color">Privacy Policy</h3>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12 mb-5 text-center">
          <div class="line"></div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p>
            Protecting your private information is our priority. This Statement
            of Privacy applies to poolchief.com, and PoolChief LLC and governs
            data collection <br />
            and usage. For the purposes of this Privacy Policy, unless otherwise
            noted, all references to PoolChief LLC include poolchief.com and
            PoolChief LLC. <br />
            The PoolChief LLC website is a marketplace site. By using the
            PoolChief LLC website, you consent to the data practices described
            in this statement. <br />
          </p>
           
          <p>
            <span class="title-terms">Collection of your Personal Information
            </span>
            <br />
            In order to better provide you with products and services offered,
            PoolChief LLC may collect personally identifiable information, such
            as your:
          </p>
           
          <p>
             - First and Last Name <br />
             - E-mail Address <br />
             - Phone Number <br />
          </p>
           
          <p>
            If you purchase PoolChief LLC’s products and services, we collect
            billing and credit card information. This information is used to
            complete the<br />
            purchase transaction.
          </p>
           
          <p>
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain <br />
            personal information to us when you elect to use certain products or
            services. These may include: (a) registering for an account; (b)
            entering a <br />
            sweepstakes or contest sponsored by us or one of our partners; (c)
            signing up for special offers from selected third parties; (d)
            sending us an email <br />
            message; (e) submitting your credit card or other payment
            information when ordering and purchasing products and services. To
            wit, we will use your <br />
            information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather <br />
            additional personal or non-personal information in the future.
          </p>
           
          <p>
            <span class="title-terms">Use of your Personal Information</span>
            <br />
            PoolChief LLC collects and uses your personal information to operate
            and deliver the services you have requested.
          </p>
           
          <p>
            PoolChief LLC may also use your personally identifiable information
            to inform you of other products or services available from PoolChief
            LLC and its affiliates.
          </p>
           
          <p>
            <span class="title-terms">Sharing Information with Third Parties
            </span>
            <br />
            PoolChief LLC does not sell, rent or lease its customer lists to
            third parties.
          </p>
           
          <p>
            PoolChief LLC may share data with trusted partners to help perform
            statistical analysis, send you email or postal mail, provide
            customer support, or <br />
            arrange for deliveries. All such third parties are prohibited from
            using your personal information except to provide these services to
            PoolChief LLC, and <br />
            they are required to maintain the confidentiality of your
            information.
          </p>
           
          <p>
            PoolChief LLC may disclose your personal information, without
            notice, if required to do so by law or in the good faith belief that
            such action is <br />
            necessary to: (a) conform to the edicts of the law or comply with
            legal process served on PoolChief LLC or the site; (b) protect and
            defend the rights <br />
            or property of PoolChief LLC; and/or (c) act under exigent
            circumstances to protect the personal safety of users of PoolChief
            LLC, or the public.
          </p>
           
          <p>
            <span class="title-terms">Tracking User Behavior</span> <br />
            PoolChief LLC may keep track of the websites and pages our users
            visit within PoolChief LLC, in order to determine what PoolChief LLC
            services are <br />
            the most popular. This data is used to deliver customized content
            and advertising within PoolChief LLC to customers whose behavior
            indicates that <br />
            they are interested in a particular subject area.
          </p>
           
          <p>
            <span class="title-terms">Automatically Collected Information </span><br />
            Information about your computer hardware and software may be
            automatically collected by PoolChief LLC. This information can
            include: your IP <br />
            address, browser type, domain names, access times and referring
            website addresses. This information is used for the operation of the
            service, to <br />
            maintain quality of the service, and to provide general statistics
            regarding use of the PoolChief LLC website.
          </p>
           
          <p>
            <span class="title-terms">Security of your Personal Information</span>
            <br />
            PoolChief LLC secures your personal information from unauthorized
            access, use, or disclosure. PoolChief LLC uses the following methods
            for this <br />
            purpose:
          </p>
           
          <p>
              - SSL Protocol <br />
              - Stripe <br />
          </p>
           
          <p>
            When personal information (such as a credit card number) is
            transmitted to other websites, it is protected through the use of
            encryption, such as the <br />
            Secure Sockets Layer (SSL) protocol.
          </p>
           
          <p>
            We strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, <br />
            no data transmission over the Internet or any wireless network can
            be guaranteed to be 100% secure. As a result, while we strive to
            protect your <br />
            personal information, you acknowledge that: (a) there are security
            and privacy limitations inherent to the Internet which are beyond
            our control; and <br />
            (b) security, integrity, and privacy of any and all information and
            data exchanged between you and us through this Site cannot be
            guaranteed.
          </p>
           
          <p>
            <span class="title-terms">Right to Deletion </span><br />
            Subject to certain exceptions set out below, on receipt of a
            verifiable request from you, we will: <br />
            • Delete your personal information from our records; and <br />
            • Direct any service providers to delete your personal information
            from their records.
          </p>
           
          <p>
            Please note that we may not be able to comply with requests to
            delete your personal information if it is necessary to: <br />
            • Complete the transaction for which the personal information was
            collected, fulfill the terms of a written warranty or product recall
            conducted in <br />
            accordance with federal law, provide a good or service requested by
            you, or reasonably anticipated within the context of our ongoing
            business <br />
            relationship with you, or otherwise perform a contract between you
            and us; <br />
            • Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity; or prosecute those responsible for
            that activity; <br />
            • Debug to identify and repair errors that impair existing intended
            functionality; <br />
            • Exercise free speech, ensure the right of another consumer to
            exercise his or her right of free speech, or exercise another right
            provided for by law; <br />
            • Comply with the California Electronic Communications Privacy Act;
            <br />
            • Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and <br />
            privacy laws, when our deletion of the information is likely to
            render impossible or seriously impair the achievement of such
            research, provided we <br />
            have obtained your informed consent; <br />
            • Enable solely internal uses that are reasonably aligned with your
            expectations based on your relationship with us; <br />
            • Comply with an existing legal obligation; or <br />
            • Otherwise use your personal information, internally, in a lawful
            manner that is compatible with the context in which you provided the
            information.
          </p>
           
          <p>
            <span class="title-terms">Children Under Thirteen </span><br />
            PoolChief LLC does not knowingly collect personally identifiable
            information from children under the age of thirteen. If you are
            under the age of <br />
            thirteen, you must ask your parent or guardian for permission to use
            this website.
          </p>
           
          <p>
            <span class="title-terms">E-mail Communications </span><br />
            From time to time, PoolChief LLC may contact you via email for the
            purpose of providing announcements, promotional offers, alerts,
            confirmations, <br />
            surveys, and/or other general communication. In order to improve our
            Services, we may receive a notification when you open an email from
            <br />
            PoolChief LLC or click on a link therein.
          </p>
           
          <p>
            If you would like to stop receiving marketing or promotional
            communications via email from PoolChief LLC, you may opt out of such
            <br />
            communications by Clicking on “unsubscribe” link on email messages.
          </p>
           
          <p>
            <span class="title-terms">Changes to this Statement </span><br />
            PoolChief LLC reserves the right to change this Privacy Policy from
            time to time. We will notify you about significant changes in the
            way we treat <br />
            personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on
            our website, and/<br />
            or by updating any privacy information. Your continued use of the
            website and/or Services available after such modifications will
            constitute your: (a) <br />
            acknowledgment of the modified Privacy Policy; and (b) agreement to
            abide and be bound by that Policy.
          </p>
           
          <p>
            <span class="title-terms">Contact Information </span><br />
            PoolChief LLC welcomes your questions or comments regarding this
            Statement of Privacy. If you believe that PoolChief LLC has not
            adhered to this <br />
            Statement, please contact PoolChief LLC at:
          </p>
           

          <p>PoolChief LLC <br /></p>
          <p>
            _________________ <br />
            Orlando, Florida 32824 <br />
          </p>
           
          <p>
            Email Address: <br />
            info@poolchief.com <br />
          </p>
           
          <p>
            Telephone number: <br />
            (321) 200-0391 <br />
          </p>
           
          <p>Effective as of April 01, 2021 <br /></p>

           
        </div>
      </div>
    </section>

    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>
