// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAdE9w2xq3SqeoxRpWqpAsrxp3Rsi9zDr8",
    authDomain: "nodal-seer-306320.firebaseapp.com",
    projectId: "nodal-seer-306320",
    storageBucket: "nodal-seer-306320.appspot.com",
    messagingSenderId: "270727389773",
    appId: "1:270727389773:web:7c6b3a7e1114f8f0a73204",
    measurementId: "G-GGNBXFWX94"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
