<mat-drawer-container hasBackdrop="true">
  <mat-drawer #drawer mode="over">
    <div class="row text-center">
      <div class="col text-center">
        <a class="navbar-brand" href="#" routerLink="/">
          <img src="./../../assets/aboutUs/logo.png" alt="" width="175px" />
        </a>
        <div class="row text-center">
          <div class="col mt-5">
            <ul class="navbar-nav text-center">
              <li class="nav-item d-block">
                <button class="btn btn-link btn-block nav-link" [routerLink]="['/service']">
                  The service
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" [routerLink]="['/how-it-works']">
                  How it works
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" [routerLink]="['/our-pool-pros']">
                  Our Pool Pros
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" [routerLink]="['/why-poolchief']">
                  Why PoolChief
                </button>
              </li>
              <li class="nav-item">
                <button class="btn btn-link btn-block nav-link" [routerLink]="['/faqs']">
                  FAQs
                </button>
              </li>
              <div class="row text-center">
                <div class="col mt-5">
                  <li class="nav-item">
                    <a class="nav-link secondary-color btn-get-quote btn" (click)="drawer.toggle(); getLocation()">Get a
                      Quote</a>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content (scroll)="onScroll($event)">
    <nav class="navbar navbar-expand-lg navbar-light" [ngClass]="isMobile ? 'mobile-navbar' : 'container'" #up>
      <div class="navbar-brand p-0 mt-2" routerLink="/">
        <img src="./../../assets/images/brand.png" alt="" width="175px" class="img-logo" />
      </div>
      <button class="navbar-toggler btn-link" type="button" (click)="drawer.toggle()">
        <mat-icon class="font-primary-color">menu</mat-icon>
      </button>

      <div class="collapse navbar-collapse navbar-align-g d-none d-lg-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <button class="btn btn-link nav-link" [routerLink]="['/service']">
              The service
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" [routerLink]="['/how-it-works']">
              How it works
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" [routerLink]="['/our-pool-pros']">
              Our Pool Pros
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" [routerLink]="['/why-poolchief']">
              Why PoolChief
            </button>
          </li>
          <li class="nav-item">
            <button class="btn btn-link nav-link" [routerLink]="['/faqs']">
              FAQs
            </button>
          </li>
          <li class="nav-item ml-4">
            <a class="nav-link primary-color btn-get-quote btn btn-get-quote-bar" (click)="getLocation()">Get a
              Quote</a>
          </li>
        </ul>
      </div>
    </nav>

    <section class="terms-space container">
      <div class="row text-center">
        <div class="col-12 mt-5">
          <h3 class="title font-primary-color">Terms of Service</h3>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12 mb-5 text-center">
          <div class="line"></div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="paragraph">
            <span class="title-terms">
              Agreement between User and poolchief.com</span>
            <br />
            Welcome to poolchief.com. The poolchief.com website (the "Site") is
            comprised of various web pages operated by PoolChief LLC ("PoolChief
            LLC").<br />
            poolchief.com is offered to you conditioned on your acceptance
            without modification of the terms, conditions, and notices contained
            herein (the<br />
            "Terms"). Your use of poolchief.com constitutes your agreement to
            all such Terms. Please read these terms carefully, and keep a copy
            of them for<br />
            your reference.
          </p>

          <p>poolchief.com is an E-Commerce Site.<br /></p>

          <p>
            PoolChief is a free online platform that connects you with hundreds
            of local homeowners who need pool cleaning services. We are like
            Uber for pool<br />
            cleaning. Recurring, paying customers delivered to you at no cost.
            Claim only the jobs that work for you, do the work, and get paid<br />
          </p>

          <p>
            <span class="title-terms">Privacy</span><br />
            Your use of poolchief.com is subject to PoolChief LLC's Privacy
            Policy. Please review our Privacy Policy, which also governs the
            Site and informs<br />
            users of our data collection practices.<br />
          </p>

          <p>
            <span class="title-terms">Electronic Communications</span> <br />
            Visiting poolchief.com or sending emails to PoolChief LLC
            constitutes electronic communications. You consent to receive
            electronic<br />
            communications and you agree that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically, via email<br />
            and on the Site, satisfy any legal requirement that such
            communications be in writing.
          </p>

          <p>
            <span class="title-terms">Your Account</span> <br />
            If you use this site, you are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer,<br />
            and you agree to accept responsibility for all activities that occur
            under your account or password. You may not assign or otherwise
            transfer your<br />
            account to any other person or entity. You acknowledge that
            PoolChief LLC is not responsible for third party access to your
            account that results from<br />
            theft or misappropriation of your account. PoolChief LLC and its
            associates reserve the right to refuse or cancel service, terminate
            accounts, or<br />
            remove or edit content in our sole discretion.
          </p>

          <p>
            <span class="title-terms">Children Under Thirteen</span> <br />
            PoolChief LLC does not knowingly collect, either online or offline,
            personal information from persons under the age of thirteen. If you
            are under 18,<br />
            you may use poolchief.com only with permission of a parent or
            guardian.<br />
          </p>

          <p>
            <span class="title-terms">Cancellation/Refund Policy</span> <br />
            Just let us know within 48 hours after the pool pro serviced your
            pool if you are not satisfied. We'll send the pro back to fix the
            issue free of charge. If<br />
            it's not resolved, we'll fully refund your money. No questions
            asked!<br />
          </p>

          <p>
            <span class="title-terms">Links to Third Party Sites/Third Party Services</span>
            <br />
            poolchief.com may contain links to other websites ("Linked Sites").
            The Linked Sites are not under the control of PoolChief LLC and
            PoolChief LLC is<br />
            not responsible for the contents of any Linked Site, including
            without limitation any link contained in a Linked Site, or any
            changes or updates to a<br />
            Linked Site. PoolChief LLC is providing these links to you only as a
            convenience, and the inclusion of any link does not imply
            endorsement by<br />
            PoolChief LLC of the site or any association with its operators.
          </p>

          <p>
            Certain services made available via poolchief.com are delivered by
            third party sites and organizations. By using any product, service
            or functionality<br />
            originating from the poolchief.com domain, you hereby acknowledge
            and consent that PoolChief LLC may share such information and data
            with any<br />
            third party with whom PoolChief LLC has a contractual relationship
            to provide the requested product, service or functionality on behalf
            of<br />
            poolchief.com users and customers.
          </p>

          <p>
            <span class="title-terms">No Unlawful or Prohibited Use/Intellectual Property</span>
            <br />
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use poolchief.com strictly in accordance with these
            terms of use.<br />
            As a condition of your use of the Site, you warrant to PoolChief LLC
            that you will not use the Site for any purpose that is unlawful or
            prohibited by<br />
            these Terms. You may not use the Site in any manner which could
            damage, disable, overburden, or impair the Site or interfere with
            any other party's<br />
            use and enjoyment of the Site. You may not obtain or attempt to
            obtain any materials or information through any means not
            intentionally made<br />
            available or provided for through the Site.
          </p>

          <p>
            All content included as part of the Service, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software
            used on the Site,<br />
            is the property of PoolChief LLC or its suppliers and protected by
            copyright and other laws that protect intellectual property and
            proprietary rights.<br />
            You agree to observe and abide by all copyright and other
            proprietary notices, legends or other restrictions contained in any
            such content and will<br />
            not make any changes thereto.
          </p>

          <p>
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the<br />
            content, in whole or in part, found on the Site. PoolChief LLC
            content is not for resale. Your use of the Site does not entitle you
            to make any<br />
            unauthorized use of any protected content, and in particular you
            will not delete or alter any proprietary rights or attribution
            notices in any content.<br />
            You will use protected content solely for your personal use, and
            will make no other use of the content without the express written
            permission of<br />
            PoolChief LLC and the copyright owner. You agree that you do not
            acquire any ownership rights in any protected content. We do not
            grant you any<br />
            licenses, express or implied, to the intellectual property of
            PoolChief LLC or our licensors except as expressly authorized by
            these Terms.
          </p>

          <p>
            <span class="title-terms">International Users</span> <br />
            The Service is controlled, operated and administered by PoolChief
            LLC from our offices within the USA. If you access the Service from
            a location<br />
            outside the USA, you are responsible for compliance with all local
            laws. You agree that you will not use the PoolChief LLC Content
            accessed through<br />
            poolchief.com in any country or in any manner prohibited by any
            applicable laws, restrictions or regulations.
          </p>

          <p>
            <span class="title-terms">Indemnification</span> <br />
            You agree to indemnify, defend and hold harmless PoolChief LLC, its
            officers, directors, employees, agents and third parties, for any
            losses, costs,<br />
            liabilities and expenses (including reasonable attorney's fees)
            relating to or arising out of your use of or inability to use the
            Site or services, any user<br />
            postings made by you, your violation of any terms of this Agreement
            or your violation of any rights of a third party, or your violation
            of any applicable<br />
            laws, rules or regulations. PoolChief LLC reserves the right, at its
            own cost, to assume the exclusive defense and control of any matter
            otherwise<br />
            subject to indemnification by you, in which event you will fully
            cooperate with PoolChief LLC in asserting any available defenses.
          </p>

          <p>
            <span class="title-terms">Arbitration</span> <br />
            In the event the parties are not able to resolve any dispute between
            them arising out of or concerning these Terms and Conditions, or any
            provisions<br />
            hereof, whether in contract, tort, or otherwise at law or in equity
            for damages or any other relief, then such dispute shall be resolved
            only by final and<br />
            binding arbitration pursuant to the Federal Arbitration Act,
            conducted by a single neutral arbitrator and administered by the
            American Arbitration<br />
            Association, or a similar arbitration service selected by the
            parties, in a location mutually agreed upon by the parties. The
            arbitrator's award shall be<br />
            final, and judgment may be entered upon it in any court having
            jurisdiction. In the event that any legal or equitable action,
            proceeding or arbitration<br />
            arises out of or concerns these Terms and Conditions, the prevailing
            party shall be entitled to recover its costs and reasonable
            attorney's fees. The<br />
            parties agree to arbitrate all disputes and claims in regards to
            these Terms and Conditions or any disputes arising as a result of
            these Terms and<br />
            Conditions, whether directly or indirectly, including Tort claims
            that are a result of these Terms and Conditions. The parties agree
            that the Federal<br />
            Arbitration Act governs the interpretation and enforcement of this
            provision. The entire dispute, including the scope and
            enforceability of this<br />
            arbitration provision shall be determined by the Arbitrator. This
            arbitration provision shall survive the termination of these Terms
            and Conditions.
          </p>

          <p>
            <span class="title-terms">Liability Disclaimer</span> <br />
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR<br />
            TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
            INFORMATION HEREIN. POOLCHIEF LLC AND/OR ITS SUPPLIERS MAY<br />
            MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
          </p>

          <p>
            POOLCHIEF LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
            SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND<br />
            ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE.<br />
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED<br />
            GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY
            KIND. POOLCHIEF LLC AND/OR ITS SUPPLIERS HEREBY<br />
            DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED<br />
            GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND<br />
            NON-INFRINGEMENT.
          </p>

          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            POOLCHIEF LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY<br />
            DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
            DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT<br />
            LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF
            OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE<br />
            OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED
            SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES,<br />
            OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
            GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE<br />
            ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT,
            NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF<br />
            POOLCHIEF LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
            POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO<br />
            NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
            OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT<br />
            APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE,
            OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE<br />
            REMEDY IS TO DISCONTINUE USING THE SITE.
          </p>

          <p>
            <span class="title-terms">Termination/Access Restriction</span>
            <br />
            PoolChief LLC reserves the right, in its sole discretion, to
            terminate your access to the Site and the related services or any
            portion thereof at any time,<br />
            without notice. To the maximum extent permitted by law, this
            agreement is governed by the laws of the State of Florida and you
            hereby consent to<br />
            the exclusive jurisdiction and venue of courts in Florida in all
            disputes arising out of or relating to the use of the Site. Use of
            the Site is unauthorized in<br />
            any jurisdiction that does not give effect to all provisions of
            these Terms, including, without limitation, this section.
          </p>

          <p>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and PoolChief LLC as a result of
            this agreement<br />
            or use of the Site. PoolChief LLC's performance of this agreement is
            subject to existing laws and legal process, and nothing contained in
            this<br />
            agreement is in derogation of PoolChief LLC's right to comply with
            governmental, court and law enforcement requests or requirements
            relating to<br />
            your use of the Site or information provided to or gathered by
            PoolChief LLC with respect to such use. If any part of this
            agreement is determined to<br />
            be invalid or unenforceable pursuant to applicable law including,
            but not limited to, the warranty disclaimers and liability
            limitations set forth above,<br />
            then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely
            matches the intent of the<br />
            original provision and the remainder of the agreement shall continue
            in effect.
          </p>

          <p>
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and PoolChief LLC with respect to
            the Site and<br />
            it supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and
            PoolChief LLC<br />
            with respect to the Site. A printed version of this agreement and of
            any notice given in electronic form shall be admissible in judicial
            or administrative<br />
            proceedings based upon or relating to this agreement to the same
            extent and subject to the same conditions as other business
            documents and<br />
            records originally generated and maintained in printed form. It is
            the express wish to the parties that this agreement and all related
            documents be<br />
            written in English.
          </p>

          <p>
            <span class="title-terms">Changes to Terms</span> <br />
            PoolChief LLC reserves the right, in its sole discretion, to change
            the Terms under which poolchief.com is offered. The most current
            version of the<br />
            Terms will supersede all previous versions. PoolChief LLC encourages
            you to periodically review the Terms to stay informed of our
            updates.
          </p>

          <p>
            <span class="title-terms">Contact Us</span> <br />
            PoolChief LLC welcomes your questions or comments regarding the
            Terms:<br />
          </p>

          <p>PoolChief LLC<br /></p>
          _________________
          <p>Orlando, Florida 32824<br /></p>

          <p>
            Email Address:<br />
            info@poolchief.com<br />
          </p>

          <p>
            Telephone number:<br />
            (321) 200-0391<br />
          </p>

          <p class="paragrapgh">Effective as of April 01, 2021<br /></p>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>
