<div class="row text-right">
  <div class="col">
    <button class="btn font-secondary-color" (click)="onNoClick()">
      <mat-icon class="font-secondary-color" style="font-weight: bold; font-size: 1.7rem;">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div [ngClass]="{'mat-dialog-container-mobile': isMobile }">
    <div class="row">
      <div class="col">
        <app-register [zipcode]="zipcode" [showButtonBack]="false"></app-register>
      </div>
    </div>
  </div>
</mat-dialog-content>
