<section class="page-tree">
  <div class="container p-5">
    <div class="row text-center">
      <div class="col-12">
        <h1 class="font-primary-color primary-title">How PoolChief Works</h1>
      </div>
    </div>
    <br /><br />
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="row text-center">
          <div class="col">
            <img src="../../assets/images/one.png" loading="lazy" alt="" class="image-number" />
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-5">
            <h4 class="font-primary-color secondary-title">View pricing</h4>
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-3">
            <span class="paragraph font-primary-color">
              We offer instant personalized quotes. Just answer a few questions
              about your pool to see your price.
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row text-center">
          <div class="col">
            <img src="../../assets/images/two.png" loading="lazy" alt="" class="image-number" />
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-5">
            <h4 class="font-primary-color secondary-title">
              Easy one-time setup
            </h4>
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-3">
            <span class="paragraph font-primary-color">
              Buy your pool cleaning service plan instantly, schedule it, and
              set up your account in the PoolChief platform.
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row text-center">
          <div class="col">
            <img src="../../assets/images/tree.png" loading="lazy" alt="" class="image-number" />
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-5">
            <h4 class="font-primary-color secondary-title">Relax and enjoy</h4>
          </div>
        </div>
        <div class="row text-center">
          <div class="col mt-3">
            <span class="paragraph font-primary-color">
              Let our pool pros do the work. Our service is all-inclusive, and
              it includes labor and supplies to keep your pool in great
              condition.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
