import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  ShowMenu = false;
  @Output() sideBarToggle = new EventEmitter<boolean>();
  opened = false;
  constructor() { }

  ngOnInit() {
  }

  toggleSideBar(){
    this.opened = !this.opened;
    this.sideBarToggle.emit(this.opened);
  }

}
