<div class="row">
  <div class="col-12 col-md-4">
    <img src="../../../assets/assets_WebP/images/become-image.webp" loading="lazy" alt="" class="image-contact d-none d-md-block">
    <img src="../../../assets/assets_WebP/img-become-mobile.webp" loading="lazy" alt="" class="image-contact d-block d-md-none">
  </div>
  <div class="col-12 col-md-8">
    <div class="row text-right">
      <div class="col">
        <button class="btn font-secondary-color" (click)="onNoClick()"><mat-icon class="font-secondary-color" style="font-weight: bold; font-size: 1.7rem;">close</mat-icon></button>
      </div>
    </div>
    <div class="p-5">
      <div class="row">
        <div class="col">
          <h3 class="font-primary-color title-modal">
            Become a Pool Pro
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h6 class="font-primary-color subtitle-modal">
            Fill out the form,  and you could be servicing new customers and earning more money as soon as tomorrow
          </h6>
        </div>
      </div>
      <form [formGroup]="poolproForm">
      <div class="row">

        <div class="col" style="margin-top: 3rem;">
          <div class="form-group">
            <input type="text" placeholder="Full name" class="form-control" formControlName="full_name">
            <span *ngIf="poolproForm.get('full_name').invalid && (poolproForm.get('full_name').dirty)">
              <span *ngIf="poolproForm.get('full_name').errors.required" style="color: brown;">
                Full Name is required.
              </span>
            </span>
          </div>
          <div class="form-group">
            <input type="email" placeholder="Email" class="form-control" formControlName="email">
            <span *ngIf="poolproForm.get('email').invalid && (poolproForm.get('email').dirty)">
              <span *ngIf="poolproForm.get('email').errors.required" style="color: brown;">
                Email is required.
              </span>
            </span>
          </div>
          <div class="form-group">
            <input type="tel" placeholder="Phone" class="form-control" formControlName="phone_number"  mask="(000)-000-0000" autocomplete="off">
            <span *ngIf="poolproForm.get('phone_number').invalid && (poolproForm.get('phone_number').dirty)">
              <span *ngIf="poolproForm.get('phone_number').errors.required" style="color: brown;">
                Phone Number is required.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-4">
          <button  mat-raised-button [class.spinner]="loading" [disabled]="loading" class="btn secondary-color text-white btn-send" type="submit" (click)="send()">SEND</button>
        </div>
      </div>
    </form>
    </div>
    </div>
</div>
