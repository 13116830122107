<div class="scrollable-content">
<h5 class="font-primary-color title-modal">What is the Address?</h5>
<h6 class="font-primary-color subtitle-modal">
  Please input the Address of where the pool is located!
</h6>

<form [formGroup]="addressForm">
  <div class="form-group mt-5">
    <input type="text" placeholder="Address" class="form-control control-label required" ngx-google-places-autocomplete
      [options]="options" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
      formControlName="street" cdkFocusInitial />
    <span *ngIf="
        addressForm.get('street').invalid && addressForm.get('street').dirty
      ">
      <span *ngIf="addressForm.get('street').errors.required" style="color: brown">
        Address is required.
      </span>
    </span>
  </div>
  <div class="form-group mt-3">
    <input type="text" placeholder="Apartment, Suite (optional)" class="form-control" formControlName="apartment"
      autocomplete="off" />
  </div>
  <div class="form-row mt-3">
    <div class="form-group col-6 col-md-5">
      <input type="text" placeholder="City" class="form-control" formControlName="city" autocomplete="off" />
      <span *ngIf="addressForm.get('city').invalid && addressForm.get('city').dirty">
        <span *ngIf="addressForm.get('city').errors.required" style="color: brown">
          City is required.
        </span>
      </span>
    </div>
    <div class="form-group col-6 col-md-3">
      <select placeholder="State" class="form-control" formControlName="state" autocomplete="off">
        <option *ngFor="let state of states; let i = index" [value]="states[i].abbreviation">
          {{ states[i].abbreviation }}
        </option>
      </select>
    </div>
    <div class="form-group col-12 col-md-4">
      <input type="text" class="form-control" formControlName="zipcode" autocomplete="off" maxlength="5"
        (keydown.enter)="onEnter()" (keypress)="numberOnly($event)" placeholder="Zip code" />
      <span *ngIf="
          addressForm.get('zipcode').invalid && addressForm.get('zipcode').dirty
        " style="color: brown">
        <span *ngIf="addressForm.get('zipcode').errors.required">Zipcode is required.</span>
        <span *ngIf="addressForm.get('zipcode').errors.pattern">Please, Enter 5 digit zipcode.</span>
      </span>
    </div>
  </div>
</form>
</div>