import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs/operators';
import { objectExists } from 'src/app/utils/utilities.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.css'],
})
export class ServiceDetailComponent implements OnInit, OnDestroy {
  @Output() childToParent = new EventEmitter<any>();
  @Output() validateForm = new EventEmitter<boolean>();
  poolDetailForm: FormGroup;

  myFlagForButtonToggle: String[] = ['Single'];
  endpointToggleOptions: Array<String> = ['Single', 'Multiple'];
  readonly currentUser$ = this.afAuth.user.pipe(filter(objectExists));
  private subscriptions = new Subscription();

  constructor(private fb: FormBuilder, public afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.poolDetailForm = this.fb.group({
      Q1: ['A'],
      Q2: ['C'],
      Q3: ['C'],
      Q4: ['C'],
      Q5: ['C'],
      Q6: ['C'],
      Q7: ['C'],
    });
    this.subscriptions.add(this.poolDetailForm.get('Q1').valueChanges.subscribe(val => {
      if (val === 'B') {
        this.toggleFormControls({ disabled: true });
      } else {
        this.toggleFormControls({ disabled: false });
      }
    }));
  }

  private toggleFormControls({ disabled }: { disabled: boolean }) {
    Object.keys(this.poolDetailForm.controls).forEach(key => {
      if(key == 'Q1') return;
      if(disabled) {
        this.poolDetailForm.get(key).disable();
        this.poolDetailForm.get(key).reset('C');
      } else {
        this.poolDetailForm.get(key).enable();
      }
    });
  }

  sendToParent() {
    this.childToParent.emit(this.poolDetailForm.value);
  }

  resetForm() {
    this.poolDetailForm.reset();
  }

  validateFormToParent() {
    this.validateForm.emit(true);
  }

  ngOnDestroy(){
    this.subscriptions.unsubscribe();
  }
}
