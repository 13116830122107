<div class="section-one-become">
  <span class="title font-primary-color">
    What is PoolChief?
  </span>
  <br>
  <span class="paragraph font-primary-color text-center">
    PoolChief is a free online platform that connects you with hundreds of local <br>
    homeowners who need pool cleaning services. We are like Uber for pool cleaning. <br>
    Recurring, paying customers delivered to you at no cost. Claim only the jobs that work <br>
    for you, do the work, and get paid.
  </span>
</div>
