<div class="row text-center">
  <div class="col">
    <img src="../../../../assets/images/checked.png" alt="" width="75px" height="75px">
    <h2 class="font-primary-color title-modal">ALL SET!</h2>
    <small class="font-primary-color subtitle-modal" style="font-size: 15px !important;">Any Additional Information?</small>
  </div>
</div>
<br>
<form [formGroup]="aditionalInfoForm">
  <div class="form-group mt-3 mb-3">
    <label for="" class="font-primary-color title-modal">Pool access instructions:</label>
    <textarea id="" cols="30" rows="3" class="form-control" placeholder="Things such as gate code, door code, lock box" formControlName="instructions"></textarea>
  </div>
  <div class="form-group mt-3 mb-3">
    <label for="" class="font-primary-color title-modal">Anything else?</label>
    <textarea id="" cols="30" rows="3" class="form-control" placeholder="Your pool pro will see these notes. Anything else you want to add." formControlName="anythingElse"></textarea>
  </div>
</form>
