import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import 'firebase/functions';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-modal-become',
  templateUrl: './modal-become.component.html',
  styleUrls: ['./modal-become.component.css']
})
export class ModalBecomeComponent implements OnInit {
  poolproForm: FormGroup;
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<ModalBecomeComponent>,
    private fb: FormBuilder,
    private firestoreService: FirestoreService
  ) { }

  ngOnInit(){
    this.poolproForm = this.fb.group({
      full_name: ['', Validators.required],
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])),
        phone_number: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async send() {
    if(!this.poolproForm.valid) {
      return;
    }

    this.loading = true;
    await this.firestoreService
    .sendMail( this.poolproForm.value,"pool-pro")
    .then((result) => {
      alertify.success('Email sent successfully!');
      this.loading = false;
      this.onNoClick();
    })
    .catch((error) => {
      alertify.error('Error occur trying to send a email. Please try again.');
      this.loading = false;
      this.onNoClick();
    });

  }
}
