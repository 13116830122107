import { FirestoreService } from 'src/app/services/firebase/firestore.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import 'firebase/functions';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { ViewChild } from '@angular/core';
import * as alertify from 'alertifyjs';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
@Component({
  selector: 'app-modal-contact',
  templateUrl: './modal-contact.component.html',
  styleUrls: ['./modal-contact.component.css'],
})
export class ModalContactComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalContactComponent>,
    private fb: FormBuilder,
    private firestoreService: FirestoreService
  ) {}

  contactUsForm: FormGroup;
  loading = false;
  fullAddress: string;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  options: Options = {
    types: [],
    componentRestrictions: { country: 'US' },
    bounds: null,
    fields: [],
    strictBounds: false,
    origin: null,
  };

  ngOnInit() {
    this.contactUsForm = this.fb.group({
      full_name: ['', Validators.required],
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),
      pool_address: [''],
      full_description: ['', Validators.required],
    });
  }

  handleAddressChange(place: object) {
    this.fullAddress = place['formatted_address'];
  }

  onNoClick() {
    this.dialogRef.close();
  }

  async send() {
    if (!this.contactUsForm.valid) {
      for (var i in this.contactUsForm.controls) {
        this.contactUsForm.controls[i].markAsDirty();
      }
      return;
    }

    this.loading = true;
   await this.firestoreService
      .sendMail(this.contactUsForm.value,"contact-us")
      .then((result) => {
        alertify.success('Email sent successfully!');
        this.loading = false;
        this.onNoClick();
      })
      .catch((error) => {
        alertify.error('Error occur trying to send a email. Please try again.');
        this.loading = false;
        this.onNoClick();
      });
  }
}
