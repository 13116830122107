import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilitiesService } from 'src/app/utils/utilities.service';
import { ModalContactComponent } from '../modal-contact/modal-contact.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  detector = navigator.userAgent;
  width: string;
  height: string;

  constructor(
    public dialog: MatDialog,
    private utils: UtilitiesService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.width = this.utils.isMobile(this.detector) ? '100vw' : '45%';
    this.height = this.utils.isMobile(this.detector) ? '90vh' : '100%';
    console.log(this.route.url)
  }

  contactUs() {
    this.openModalContact(this.width, this.height);
  }

  openModalContact(width: string, height: string) {
    this.dialog.open(ModalContactComponent, {
      panelClass: 'contact-dialog-container',
      maxWidth: width,
      minHeight: '614px',
      width: '100%',
      height: 'auto',
      disableClose: true
    });
  }



}
