<section class="page-five">
  <div class="container">
    <div class="row">
      <div class="col mb-4">
        <h3 class="font-primary-color title">Why PoolChief</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-4 mt-3 mb-4">
        <div class="row">
          <div class="col mt-4">
            <img src="../../assets/images/icon-click.png" loading="lazy" alt="" class="image-icon">
          </div>
        </div>
        <div class="row">
          <div class="col mt-3 mb-1">
            <span class="font-primary-color secondary-title">Easy, Convenient, Fast</span>
          </div>
        </div>
        <div class="row">
          <div class="col paragraph font-primary-color">
            <span>Online with an instant and
              personalized price. No need to
              haggle over bids, read dozens of
              reviews, or worry about quality.
            </span>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 mt-3 mb-4">
        <div class="row">
          <div class="col mt-4">
            <img src="../../assets/images/icon-arrow.png" loading="lazy" alt="" class="image-icon">
          </div>
        </div>
        <div class="row">
          <div class="col mt-3 mb-1">
            <span class="font-primary-color secondary-title sub-title-page-five">All Services Covered
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col paragraph font-primary-color">
            <span>Our all-inclusive and convenient
              service includes vacuuming,
              brushing, skimming, supplies
              and chemical management.
            </span>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 mt-3 mb-4">
        <div class="row">
          <div class="col mt-4">
            <img src="../../assets/images/icon-medal.png" loading="lazy" alt="" class="image-icon">
          </div>
        </div>
        <div class="row">
          <div class="col mt-3 mb-1">
            <span class="font-primary-color secondary-title sub-title-page-five">Fully Vetted Pool Pros
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col paragraph font-primary-color">
            <span>Each PoolChief contractor must
              pass our screening process to
              verify their experience and skills.
            </span>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 mt-3 mb-4">
        <div class="row">
          <div class="col mt-4">
            <img src="../../assets/images/icon-laptop.png" loading="lazy" alt="" class="image-icon">
          </div>
        </div>
        <div class="row">
          <div class="col mt-3 mb-1">
            <span class="font-primary-color secondary-title">All-in-One Online Account
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col paragraph font-primary-color">
            <span>Payment, scheduling,
              feedback, pool condition reports… all done securely through
              the PoolChief platform.

            </span>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 mt-3 mb-4">
        <div class="row">
          <div class="col mt-4">
            <img src="../../assets/images/icon-hand.png" loading="lazy" alt="" class="image-icon">
          </div>
        </div>
        <div class="row">
          <div class="col mt-3 mb-1">
            <span class="font-primary-color secondary-title">Affordable
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col paragraph font-primary-color">
            <span>You’ll get a fair price for the most cost-effective & modern pool
              cleaning service with fantastic customer experience.

            </span>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 mt-3 mb-4">
        <div class="row">
          <div class="col mt-4">
            <img src="../../assets/images/icon-like.png" loading="lazy" alt="" class="image-icon">
          </div>
        </div>
        <div class="row">
          <div class="col mt-3 mb-1">
            <span class="font-primary-color secondary-title sub-title-page-five">100% Satisfaction Guarantee

            </span>
          </div>
        </div>
        <div class="row">
          <div class="col paragraph font-primary-color">
            <span>We only charge you when service is completed. If you aren’t happy,
              we’ll refund your money, no questions asked.

            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
