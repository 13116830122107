<section class="banner">
  <div class="container">
    <div class="row">
      <div class="col-3">
        <div class="row text-center">
          <div class="col-12">
            <img src="../../../assets/aboutUs/customer service.png" loading="lazy" width="100" height="100" class="img-banner-about">
          </div>
          <div class="col-12 mt-3">
            <span class="paragraph">
              Excellent Customer <br> Service
            </span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row text-center">
          <div class="col-12">
            <img src="../../../assets/aboutUs/hand.png" loading="lazy" width="100" height="100" class="img-banner-about">
          </div>
          <div class="col-12 mt-3">
            <span class="paragraph">
              Affordable and <br> Reliable
            </span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row text-center">
          <div class="col-12">
            <img src="../../../assets/aboutUs/medal.png" loading="lazy" width="90" height="100" class="img-banner-about">
          </div>
          <div class="col-12 mt-3">
            <span class="paragraph">
              Fully Vetted <br> Pool Pros
            </span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row text-center">
          <div class="col-12">
            <img src="../../../assets/aboutUs/hourglass.png" loading="lazy" width="90" height="100" class="img-banner-about">
          </div>
          <div class="col-12 mt-3">
            <span class="paragraph">
              Fast Online <br> Ordering
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
