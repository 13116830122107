import { FirestoreService } from '../services/firebase/firestore.service';
import { UtilitiesService } from './../utils/utilities.service';
import { ModalLayoutComponent } from 'src/app/shared/components/modal-layout/modal-layout.component';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { } from 'googlemaps';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressComponent } from '../shared/components/address/address.component';
import { ModalContactComponent } from '../landing/modal-contact/modal-contact.component';
import { ModalBecomeComponent } from '../landing/modal-become/modal-become.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css'],
})
export class PrivacyComponent implements OnInit {
  @ViewChild(AddressComponent) poolAddressComponent: AddressComponent;
  zipcode: number;
  detector = navigator.userAgent;
  geocoder: any;
  formattedAddress: string;
  width: string;
  height: string;
  basePrice: number;
  scrollUp = false;
  isMobile: boolean;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private utils: UtilitiesService,
    private gtmService: GoogleTagManagerService,
  ) { }
  zipCodeForm: FormGroup;

  ngOnInit() {
    this.width = this.utils.isMobile(this.detector) ? '100vw' : '600px';
    this.height = this.utils.isMobile(this.detector) ? '90vh' : '550px';
    this.isMobile = this.utils.isMobile(this.detector);
    this.zipCodeForm = this.fb.group({
      zipcode: [''],
      zipcodeFooter: [''],
    });
  }

  openModal(width: string, height: string) {
    const gtmTag = {
      event: 'click_on_see_my_price',
      zipcode: this.zipCodeForm.value.zipcode
    };

    this.gtmService.pushTag(gtmTag);

    const dialogRef = this.dialog.open(ModalLayoutComponent, {
      maxWidth: width,
      minHeight: 'calc(100% - 90px)',
      width: '100%',
      height: 'auto',
      data: {
        zipCode: this.zipCodeForm.value.zipcode,
        isMobile: this.utils.isMobile(this.detector),
        completedRegister: false,
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      dialogRef.afterClosed().subscribe((result) => { });
    });
  }

  openModalCompleteRegister(width: string, height: string) {
    const gtmTag = {
      event: 'click_on_see_my_price',
      zipcode: this.zipCodeForm.value.zipcode
    };

    this.gtmService.pushTag(gtmTag);

    const dialogRef = this.dialog.open(ModalLayoutComponent, {
      maxWidth: width,
      minHeight: 'calc(100% - 90px)',
      width: '100%',
      height: 'auto',
      data: {
        zipCode: this.zipCodeForm.value.zipcode,
        isMobile: this.utils.isMobile(this.detector),
        completedRegister: true,
        basePrice: this.basePrice,
        disableClose: true
      },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  async getLocation() {
    this.openModal(this.width, this.height);
  }

  openRegisterModal() {
    this.openModal(this.width, this.height);
  }

  scrollToElement(element): void {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (window.scrollY > 1500) {
      this.scrollUp = true;
    } else {
      this.scrollUp = false;
    }
  }

  contactUs() {
    this.openModalContact(this.width, this.height);
  }

  become() {
    this.openModalBecome(this.width, this.height);
  }

  openModalContact(width: string, height: string) {
    this.dialog.open(ModalContactComponent, {
      panelClass: 'contact-dialog-container',
      maxWidth: width,
      maxHeight: height,
      width: '100%',
      disableClose: true
    });
  }

  openModalBecome(width: string, height: string) {
    this.dialog.open(ModalBecomeComponent, {
      maxWidth: width,
      maxHeight: height,
      width: '100%',
      disableClose: true
    });
  }
}
