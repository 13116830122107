import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { differenceInCalendarDays } from 'date-fns';
import {
  Component,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Input } from '@angular/core';
import 'firebase/functions';
import { CommonService } from 'src/app/utils/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UtilitiesService } from 'src/app/utils/utilities.service';
import { STRIPE_ELEMENTS } from 'src/app/enums/stripe-elements.enum';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() subscription: boolean;
  @Input() servicePrice: number;
  @Input() service: string;
  @Output() childToParent = new EventEmitter();
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('picker') picker;
  cardHandler = this.onChange.bind(this);
  monthly: number;
  card: any;
  exp: any;
  cvc: any;
  cardError: string;
  customerId: string;
  paymentMethod: any;
  loading = false;
  formValid = false;
  isMobile: boolean;
  date = null;
  poolAddress: string;
  startDate: number;
  firtsDay = '';
  endDate = '';
  dateFormat = '';
  thisWeekDates = [];
  weekDatesFormated = [];
  today = new Date();
  dateForm: FormGroup;
  detector = navigator.userAgent;
  public validationErrors: { [key in STRIPE_ELEMENTS]: { isEmpty: boolean, isDirty: boolean } } = {
    cardNumber: {
      isDirty: false,
      isEmpty: true,
    },
    cardExpiry: {
      isDirty: false,
      isEmpty: true,
    },
    cardCvc: {
      isDirty: false,
      isEmpty: true,
    },
  };

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    public afAuth: AngularFireAuth,
    private commonService: CommonService,
    private i18n: NzI18nService,
    private utils: UtilitiesService,
  ) { }

  ngOnInit() {
    this.monthly = this.commonService.get('_').monthly;
    this.dateForm = this.fb.group({
      startDate: ['', Validators.required],
      zipcode: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{5}$')],
      ],
    });

    this.poolAddress = this.commonService.get('poolAddress');
    this.i18n.setLocale(en_US);

    this.thisWeekDates = this.getThisWeekDates();
    this.thisWeekDates.forEach((date) => {
      this.weekDatesFormated.push(date);
    });
    this.isMobile = this.utils.isMobile(this.detector);
  }

  ngAfterViewInit() {
    var style = {
      base: {
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {},
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    var elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };
    // Card number
    this.card = elements.create(STRIPE_ELEMENTS.cardNumber, {
      placeholder: 'Credit / Debit card number',
      showIcon: true,
      style: style,
      class: elementClasses,
      hidePostalCode: false,
    });
    this.card.mount('#card-number');
    this.card.addEventListener('change', this.cardHandler);

    // Card expiry
    this.exp = elements.create(STRIPE_ELEMENTS.cardExpiry, {
      placeholder: 'MM/YY',
      style: style,
      class: elementClasses,
    });
    this.exp.mount('#card-exp');
    this.exp.addEventListener('change', this.cardHandler);

    // CVC
    this.cvc = elements.create(STRIPE_ELEMENTS.cardCvc, {
      placeholder: 'Security Code',
      style: style,
      class: elementClasses,
    });
    this.cvc.mount('#card-cvc');
    this.cvc.addEventListener('change', this.cardHandler);

    this.registerElements([this.card, this.cvc, this.exp]);
  }

  // Listen for errors from each Element, and show error messages in the UI.
  registerElements(elements) {
    var savedErrors = [];
    elements.forEach((element, idx) => {
      element.on('change', (event) => {
        savedErrors[idx] = event;

        if (savedErrors.length == elements.length) {
          this.formValid = savedErrors.every(
            (element) => !element.error && element.complete
          );
        } else {
          this.formValid = false;
        }
      });
    });
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();

    this.cvc.removeEventListener('change', this.cardHandler);
    this.cvc.destroy();

    this.exp.removeEventListener('change', this.cardHandler);
    this.exp.destroy();
  }

  onChange({ elementType, empty}: {elementType: STRIPE_ELEMENTS, empty: boolean}) {
    this.validationErrors[elementType].isDirty = true;
    this.validationErrors[elementType].isEmpty = empty;
    this.cd.detectChanges();
  }

  getWeek(result: Date) {
    if (result) {
      this.startDate = Math.floor(this.getMonday(result).getTime() / 1000.0);
      this.firtsDay = this.getMonday(result).toLocaleDateString('en-US');
      this.endDate = this.getNextSunday(this.firtsDay).toLocaleDateString('en-US');
      this.dateFormat = this.firtsDay + ' - ' + this.endDate;
    }
  }

  disabledDate = (current: Date): boolean => {
    if (differenceInCalendarDays(current, this.today) < 0) {
      return true;
    }

    if (differenceInCalendarDays(current, this.today) > 31) {
      return true;
    }

    // for (const dateItem of this.weekDatesFormated) {
    //   let days = differenceInCalendarDays(dateItem, this.today);
    //   if (differenceInCalendarDays(current, this.today) === days) {
    //     return true;
    //   }
    // }
  };

  getThisWeekDates() {
    var weekDates = [];
    for (var i = 1; i <= 7; i++) {
      weekDates.push(moment().day(i).toDate());
    }
    return weekDates;
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getNextSunday(d) {
    d = new Date(d);
    var diff = d.getDate() + 6;
    return new Date(d.setDate(diff));
  }
}
