import { FirestoreService } from './../../../services/firebase/firestore.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UtilitiesService } from 'src/app/utils/utilities.service';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-getquote',
  templateUrl: './getquote.component.html',
  styleUrls: ['./getquote.component.css']
})
export class GetquoteComponent implements OnInit {
  zipCodeForm: FormGroup;
  detector = navigator.userAgent;
  width: string;
  height: string;
  zipcode: number;
  basePrice: number;
  constructor(
    private fb: FormBuilder,
    private utils: UtilitiesService,
    public dialog: MatDialog,
    private gtmService: GoogleTagManagerService) { }

  ngOnInit() {
    this.width = this.utils.isMobile(this.detector) ? '100vw' : '600px';
    this.height = this.utils.isMobile(this.detector) ? '70vh' : '550px';
    this.zipCodeForm = this.fb.group({
      zipcode: ['']
    });
  }

  openModal(width: string, height: string) {
    const gtmTag = {
      event: 'click_on_see_my_price',
      zipcode: this.zipCodeForm.value.zipcode
    };

    this.gtmService.pushTag(gtmTag);

    this.dialog.open(ModalLayoutComponent, {
      maxWidth: width,
      minHeight: 'calc(90% - 90px)',
      width: '100%',
      height: 'auto',
      data: {
        zipCode: this.zipCodeForm.value.zipcode,
        isMobile: this.utils.isMobile(this.detector),
        completedRegister: false,
      },
      disableClose: true
    });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getLocation() {
    this.openModal(this.width, this.height);
  }

}
