<section class="page-tree">
  <div class="container p-5">
    <div class="row text-center">
      <div class="col-12">
        <h1 class="font-primary-color primary-title">Our Mission</h1>
      </div>
    </div>
    <div class="row text-center">
      <div class="col mt-4">
        <span class="paragraph font-primary-color" style="line-height: normal;">
          Revolutionize the pool cleaning industry by creating <br>
          the largest high-tech pool service marketplace that <br>
          delivers a fantastic customer experience, and helps <br>
          local pool pros thrive and grow.
        </span>
      </div>
    </div>
  </div>
</section>

