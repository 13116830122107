<mat-toolbar class="navbar-container">
  <button mat-icon-button class="d-block d-md-none" (click)="toggleSideBar()">
    <mat-icon>menu</mat-icon>
  </button>
  <span>
    <a class="navbar-brand" href="" routerLink="/Services">
      <img src="./../../assets/logo/PoolChief_Assets-05.svg" alt="" width="175px">
    </a>
  </span>
  <div class="navbar-banner d-none d-md-block">
    <small class="text-white" style=" font-size: 10pt; font-weight: normal;">Free Service Credit: {{4}}</small>
  </div>
  <span class="example-spacer"></span>
  <button mat-icon-button class="example-icon favorite-icon d-none d-md-block">
    <mat-icon>notifications</mat-icon>
  </button>

  <div class="dropdown d-none d-md-block" [ngClass]="ShowMenu ? 'show': '' ">
    <button class="btn dropdown-toggle" type="button"  aria-haspopup="true" aria-expanded="false" (click)="ShowMenu = !ShowMenu">
      <ngx-avatar name="John Doe"></ngx-avatar>&nbsp;&nbsp;&nbsp; John Doe
    </button>
    <div class="dropdown-menu" [ngClass]="ShowMenu ? 'show': '' " aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="#">My Profile</a>
      <a class="dropdown-item">Log out</a>
    </div>
  </div>
</mat-toolbar>
