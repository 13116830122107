<section class="page-four">
  <div class="container">
    <div class="row">
      <div class="col  content-page-three">
        <h3  class="font-primary-color title">An Optimized Local Ecosystem</h3>

        <p class="paragraph font-primary-color mt-3">
          We screen and select the best local pool pros, equip them
          with our robust tech platform, and match them to your
          swimming pool and preferences.
          <br><br>
          We save you time and money by making it more
          comfortable, secure, and agile to hire, pay, and manage
          pool cleaning services.
          <br><br>
          On the other hand, the local pool pros can focus on what
          they do best, maintaining your swimming pool in the best
          possible condition.
          <br><br>
          We figure out everything from scheduling, routing,
          payment processing, quality control, and more so that you
          can sit back, relax, and enjoy your beautiful swimming
          pool.
        </p>
      </div>
      <div class="d-none d-lg-block col-6">
        <img src="../../../assets/assets_WebP/images/landing-two.webp" alt="" class="img-fluid" loading="lazy">
      </div>
    </div>
  </div>
</section>
