<section class="page-two padding container">
  <div class="row">
    <div class="d-none d-xl-block col-4">
      <div class="img-container">
        <img
          src="../../../assets/aboutUs/star.png"
          loading="lazy"
          width="350"
          height="150"
          class="image-one"
        />
      </div>
    </div>
    <div class="col-1 d-none d-md-block"></div>

    <div class="col-12 col-xl-7 content-page-two">
      <p class="paragraph font-primary-color">
        <span class="font-primary-color title">Satisfaction Guaranteed</span>
        <br /><br />
        PoolChief makes it easy to find the very best pool pro near you. Our
        goal is that your experience with PoolChief is so incredible that you
        tell your neighbors and friends about us.
        <br /><br />
        However, nothing is perfect, and sometimes you might not be satisfied
        with your pool cleaning service. That's why we offer a 100% total
        satisfaction guaranteed.
        <br /><br />
        Just let us know within 48 hours after the pool pro serviced your pool.
        We'll send the pro back to fix the issue free of charge. If it's not
        resolved, we'll fully refund your money. No questions asked!
      </p>
    </div>
  </div>
</section>
