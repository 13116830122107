<div class="sidenav-content primary-color">

  <a href="" class="link-sideNav" [routerLinkActive]="'active'" routerLink="/Services">
    <div>
      <mat-icon aria-hidden="false" class="mr-2">home</mat-icon>
    </div>
    <span>Services</span>
  </a>

  <a href="" class="link-sideNav" [routerLinkActive]="'active'" routerLink="/My-pools">
    <div>
      <mat-icon aria-hidden="false" class="mr-2">place</mat-icon>
    </div>
    <span>My pools</span>
  </a>

  <a href="" class="link-sideNav" [routerLinkActive]="'active'" routerLink="/Support">
    <div>
      <mat-icon aria-hidden="false" class="mr-2">build</mat-icon>
    </div>
    <span>Support</span>
  </a>

  <a href="" class="link-sideNav" [routerLinkActive]="'active'" routerLink="/My-Profile">
    <div>
      <mat-icon aria-hidden="false" class="mr-2">account_circle</mat-icon>
    </div>
    <span>My profile</span>
  </a>

</div>
