import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/modal-data';
import { CommonService } from 'src/app/utils/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.css']
})
export class ModalLayoutComponent implements OnInit {
  @Output() closeFromX = new EventEmitter<boolean>();
  zipcode: number;
  isMobile: boolean;
  completeRegister: boolean;
  private _originalClose: Function;

  constructor(
    public dialogRef: MatDialogRef<ModalLayoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService) {
    this._originalClose = this.dialogRef.close;
    this.dialogRef.close = this.closeDialog.bind(this);
  }

  ngOnInit(): void {
    this.zipcode = this.data.zipCode;
    this.isMobile = this.data.isMobile;
    this.completeRegister = this.data.completedRegister;
    this.commonService.serviceBooked = false;
  }

  closeDialog() {
    if(this.commonService.serviceBooked) {
      this._originalClose.bind(this.dialogRef)();
    } else {
      this.showConfirmationModal().then(res=> {
        if(res?.close) this._originalClose.bind(this.dialogRef)();
      })
    }
  }

  async showConfirmationModal(): Promise<{ close: boolean }> {
    const result = await Swal.fire({
      showDenyButton: true,
      title: 'You are almost there!',
      denyButtonText: 'Exit',
      confirmButtonText: 'Get my Quote Now',
      customClass: {
        confirmButton: 'btn bold confirm-btn',
        denyButton: 'btn bold deny-btn',
      }
    });
    if (result.isConfirmed) {
      return { close: false }
    } else if(result.isDenied){
      return { close: true }
    }
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

}
