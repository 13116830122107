import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  sessionStorage: Storage;
  public serviceBooked: boolean = false;
  constructor() {
    this.sessionStorage = window.sessionStorage;
  }
  geocoder: any;
  map: any;

  codeAddress(postcode) {
    this.geocoder = new google.maps.Geocoder();
    this.geocoder.geocode({ address: postcode }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
       return results;
      } else {
        return
      }
    });
  }

  getAddrComponent(place, componentTemplate) {
    let result;

    for(let i = 0; i < place.address_components.length; i++)  {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = {street_number: 'short_name'},
    streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getStreet(place){
    const COMPONENT_TEMPLATE = { route: 'long_name'},
    street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getCity(place) {
    const COMPONENT_TEMPLATE = {locality: 'long_name' },
    city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getDistrict(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getCountryShort(place) {
    const COMPONENT_TEMPLATE = { country: 'short_name' },
      countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return countryShort;
  }

  getCountry(place: object) {
    const COMPONENT_TEMPLATE = { country: 'long_name' },
      country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getPostCode(place: object) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      return JSON.parse(this.sessionStorage.getItem(key));
    }
    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.sessionStorage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.sessionStorage.removeItem(key);
      return true;
    }
    return false;
  }
  get isLocalStorageSupported(): boolean {
    return !!this.sessionStorage
  }
}
