// import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SharedModule } from './shared/shared.module';
import { NavBarComponent } from './layout/nav-bar/nav-bar.component';
import { SideBarComponent } from './layout/side-bar/side-bar.component';
import { RoutesRoutingModule } from './routes/routes-routing.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LandingComponent } from './landing/landing.component';
import { SharedMaterialModule } from './shared/shared-material.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AvatarModule } from 'ngx-avatar';
import { HttpClientModule } from '@angular/common/http';
import { SupportComponent } from './support/support.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { SectionOneComponent } from './landing/section-one/section-one.component';
import { SectionTwoComponent } from './landing/section-two/section-two.component';
import { SectionThreeComponent } from './landing/section-three/section-three.component';
import { SectionFourComponent } from './landing/section-four/section-four.component';
import { SectionFiveComponent } from './landing/section-five/section-five.component';
import { ModalBecomeComponent } from './landing/modal-become/modal-become.component';
import { BecomeAPoolProComponent } from './become-a-pool-pro/become-a-pool-pro.component';
import { SectionOneBecomeComponent } from './become-a-pool-pro/section-one-become/section-one-become.component';
import { SectionTwoBecomeComponent } from './become-a-pool-pro/section-two-become/section-two-become.component';
import { SectionThreeBecomeComponent } from './become-a-pool-pro/section-three-become/section-three-become.component';
import { SectionFourBecomeComponent } from './become-a-pool-pro/section-four-become/section-four-become.component';
import { NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
const maskConfig: Partial<IConfig> = {
  validation: true,

};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SideBarComponent,
    NavBarComponent,
    LandingComponent,
    SupportComponent,
    MyProfileComponent,
    SectionFiveComponent,

    ModalBecomeComponent,
    BecomeAPoolProComponent,
    SectionOneBecomeComponent,
    SectionTwoBecomeComponent,
    SectionThreeBecomeComponent,
    SectionFourBecomeComponent,
    TermsComponent,
    PrivacyComponent,
    SectionOneComponent,
    SectionTwoComponent,
    SectionThreeComponent,
    SectionFourComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(maskConfig),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-K23RX2M'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    RoutesRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    SharedMaterialModule,
    AvatarModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
        // week starts on Monday (Sunday is 0)
      },
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
