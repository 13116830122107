import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class MetaService {
  constructor() { }

  landingTitle() {
    return "Hire the Best Pool Cleaning Service Near You - PoolChief";
  }

  landingDescription() {
    return "Fully vetted pros. Affordable, fast online ordering, satisfaction guaranteed, and fantastic customer service. Request a Quote now! Take less than a minute.";
  }

  aboutUsTitle() {
    return "About us - PoolChief";
  }

  aboutUsDescription() {
    return "An innovative marketplace that leverages technology to connect swimming pool owners with the best local pool cleaning pros.";
  }

  poolProTitle() {
    return "Become a Pool Pro - PoolChief";
  }

  poolProDescription() {
    return "Start adding profitable accounts to your pool route for free. Recurring, paying customers delivered to you at no cost. Join us today!";
  }
}
