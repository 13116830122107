<app-nav-bar (sideBarToggle)="toogleSideBar($event)"></app-nav-bar>

<mat-sidenav-container class="example-container" >
  <mat-sidenav mode="side" [opened]="opened" class="sidenav-container">
    <app-side-bar></app-side-bar>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

